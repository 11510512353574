import React from "react";
import { errorMessage} from "../config/config";

export function FormValidationError({formDataList=[],type="input"}) {
    let requiredMessageText = errorMessage.required; // デフォルトのエラーメッセージ
    let rangeMessageText = errorMessage.shortRange; // デフォルトのエラーメッセージ

    if (["select","calendar"].includes(type)) {
        requiredMessageText = errorMessage.requiredSelect;
    }else if(type === "addressSelect"){
        requiredMessageText = errorMessage.requiredAddress;
    }
    else if(type === "yubinBangou"){
        requiredMessageText = errorMessage.requiredYubinBangou;
        rangeMessageText = errorMessage.shortRangeYubinBangou;
    }
    return (
        <>
            {formDataList.some(formData => formData.requiredError === true) && <p className="validation-error-txt">{requiredMessageText}</p>}
            {formDataList.some(formData => formData.correlationRequiredError === true) && <p className="validation-error-txt">{requiredMessageText}</p>}
            {formDataList.some(formData => formData.rangeError === true) && <p className="validation-error-txt">{rangeMessageText}</p>}
            {formDataList.some(formData => formData.kanaError === true) && <p className="validation-error-txt">{errorMessage.kanaFormat}</p>}
            {formDataList.some(formData => formData.phoneFormatError === true) && <p className="validation-error-txt">{errorMessage.phoneFormat}</p>}
            {formDataList.some(formData => formData.notKyusyuError === true) && <p className="validation-error-txt">{errorMessage.notKyusyu}</p>}
            {formDataList.some(formData => formData.notFoundAddressError === true) && <p className="validation-error-txt">{errorMessage.notFoundAddress}</p>}
            {formDataList.some(formData => formData.rangeErrorSubmit === true) && <p className="validation-error-txt">{errorMessage.shortRange}</p>}
            {formDataList.some(formData => formData.hankakuError === true) && <p className="validation-error-txt">{errorMessage.zenkakuFormat}</p>}
            {formDataList.some(formData => formData.duplicationError === true) && <p className="validation-error-txt">{errorMessage.duplicateMonth}</p>}
            {formDataList.some(formData => formData.authError === true) && <p className="validation-error-txt">{errorMessage.authFail}</p>}
            {formDataList.some(formData => formData.expiredError === true) && <p className="validation-error-txt">{errorMessage.authExpired}</p>}
            {formDataList.some(formData => formData.A001_CorrelationRequiredError === true) && <p className="validation-error-txt">{errorMessage.A001_CorrelationRequired}</p>}            
        </>
    );
}
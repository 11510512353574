import React from "react";

export function InputText({name,formData,handleValue,className=""}){
    const errorClass = formData.requiredError || formData.rangeError || formData.kanaError || formData.hankakuError || formData.correlationRequiredError ? "is-invalid "+className : className;
    function handleInputChange(e) {
        handleValue(e.target.name,e.target.value)
    }
    return (
        <>
             <input
            type="text"
            name={name}
            onChange={handleInputChange}
            maxLength={formData.maxLength}
            value={formData.value}
            className={errorClass}
        />
        </>
    );
}
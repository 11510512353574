import React, { useEffect } from "react";
import {required,isShortRange, isPhoneFormat} from "../util/validate";
import { FORM_G001 } from "../config/form";
import {call_api} from "../util/util";
import { errorMessage,displayName } from "../config/config";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { SystemError } from "../components/SystemError";
import liff from '@line/liff';

export function G001(props) {
  const [values, setValues] = React.useState(FORM_G001);
  const [room_id, setRoomID] = React.useState([]);
  let errCount = 0
  useEffect(() => {
    document.title = displayName.G001;
    setRoomID(props.roomId)
  },[]);

  //type=tel
  function handleInputChangeNum(e) {
    const re = /^[0-9\b]+$/;
    const target = e.target;
    const value = target.value;
    const name = target.name;
    //正規表現に一致したもののみ設定
    if (value === '' || re.test(value)) {
        handleValue(name,value)
    }else{
        setValues({ ...values, [name]: values[name] });
    }
  }

  function handleValue(name,value) {
    let copy = values[name]
    copy["value"] = value
    setValues({ ...values, [name]: copy });    
  }
  function handleError(name,error,bool) {
    let copy = values[name]
    copy[error] = bool
    setValues({ ...values, [name]: copy });    
    if(bool)errCount = errCount + 1
  }

  async function submit(){
    //入力チェック
    errCount = 0
    handleError("phoneNumber1","requiredError",required(values.phoneNumber1.value))
    handleError("phoneNumber1","rangeError",isShortRange(values.phoneNumber1.value,3))
    handleError("phoneNumber1","phoneFormatError",isPhoneFormat(values.phoneNumber1.value))

    handleError("phoneNumber2","requiredError",required(values.phoneNumber2.value))
    handleError("phoneNumber2","rangeError",isShortRange(values.phoneNumber2.value,4))

    handleError("phoneNumber3","requiredError",required(values.phoneNumber3.value))
    handleError("phoneNumber3","rangeError",isShortRange(values.phoneNumber3.value,4))

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    //API連携
    if(errCount === 0){
      let form = {
        "room_id":room_id,
        "cellphone_number":String(values.phoneNumber1.value) + String(values.phoneNumber2.value) + String(values.phoneNumber3.value),
      }
      let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB004",form)
      if(result.status_code === "200"){
        setValues({ ...values, "systemError": false });
        liff.closeWindow()
        window.close()
      }else{
        setValues({ ...values, "systemError": true });
      }
    }
  }
  return (
    <div>
      <Header label={displayName.G001}/>
      <div id="form">
      <form action="">
        <section className="area">
          <section>
            <h2>認証コードを送信する携帯電話番号を入力ください。</h2>
            <div className="formSet multiNum">
              <h3 className="required">携帯電話番号</h3>
              <p>
                <input type="tel" name="phoneNumber1" onChange={handleInputChangeNum} maxLength="3" className={values.phoneNumber1.requiredError || values.phoneNumber1.rangeError || values.phoneNumber1.phoneFormatError ? "is-invalid fg01" : "fg01"} value={values.phoneNumber1.value} placeholder="例）090" /><span>-</span>
                <input type="tel" name="phoneNumber2" onChange={handleInputChangeNum} maxLength="4" className={values.phoneNumber2.requiredError || values.phoneNumber2.rangeError ? "is-invalid fg01" : "fg01"} value={values.phoneNumber2.value} placeholder="1234" /><span>-</span>
                <input type="tel" name="phoneNumber3" onChange={handleInputChangeNum} maxLength="4" className={values.phoneNumber3.requiredError || values.phoneNumber3.rangeError? "is-invalid fg01" : "fg01"} value={values.phoneNumber3.value} placeholder="5678" />
              </p>
              {(values.phoneNumber1.requiredError || values.phoneNumber2.requiredError || values.phoneNumber3.requiredError) ? <p className="validation-error-txt">{errorMessage.required}</p> :""}
              {(values.phoneNumber1.rangeError || values.phoneNumber2.rangeError || values.phoneNumber3.rangeError) ? <p className="validation-error-txt">{errorMessage.shortRange}</p> :""}
              {values.phoneNumber1.phoneFormatError && <p className="validation-error-txt">{errorMessage.phoneFormat}</p>}
            </div>
          </section>
        </section>
        <section className="submit">
          <ul>
            <li>
            <button type="button" onClick={submit} className="activeBtn"><span className="btn_inner">送信</span></button>
            </li>
          </ul>
        </section>
        {values.systemError && <SystemError />}
      </form>
    </div>
      <Footer/>
    </div>
  );
}
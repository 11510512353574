import React from 'react';

export function Loading(props) {
    return (
        <>
                  <div id="loader-bg" className="loader-bg">
                    <div id="loader" className="loader">
                        <p>{props.text}</p>
                    </div>
                </div>
                <div id="loader-bga" className="loader-bg" style={{ display: 'block' }}>
                    <div id="loadera" className="loader" style={{ display: 'block' }}>
                        <p>{props.text}</p>
                    </div>
                </div>
        </>
    );
}

export default Loading;
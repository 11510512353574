import React, { useEffect } from "react";

export function PaymentResult({paymentAmount1,paymentStatus1,paymentAmount2,paymentStatus2,searchedDate,alreadyQueried}) {
    const [paymentHtml1, setPaymentHtml1] = React.useState((<p className="payment-card_txt02">該当データはありません</p>));
    const [paymentHtml2, setPaymentHtml2] = React.useState((<p className="payment-card_txt02">該当データはありません</p>));
    useEffect(() => {
        if(paymentAmount1){
            setPaymentHtml1((<p className="payment-card_txt02"><span>{numberWithCommas(paymentAmount1)}</span>円（{paymentStatus1}）</p>))
        }
        if(paymentAmount2){
            setPaymentHtml2((<p className="payment-card_txt02"><span>{numberWithCommas(paymentAmount2)}</span>円（{paymentStatus2}）</p>))
        }
    },[paymentAmount1,paymentAmount2]);

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <>

            <h2 className="mt40">支払い状況</h2>
            {alreadyQueried &&
                <div className="payment-card">
                    <p className="payment-card_txt01">対象年月1：</p>
                    <p className="payment-card_txt02">{paymentHtml1}</p>
                    <p className="payment-card_txt01">対象年月2：</p>
                    <p className="payment-card_txt02">{paymentHtml2}</p>
                    <p className="payment-card_supplement">※入金情報は{searchedDate}の情報です。</p>
                    <p className="payment-card_supplement">※お支払いいただいた金融機関やコンビニエンスストアによって、反映時間が異なることがあります。</p>
                </div>
            }{!alreadyQueried &&
                <div className="payment-card">
                    <p className="payment-card_supplement layout-centering">支払い情報はありません</p>
                </div>
            }
        </>
    );
}
import React from "react";
import Select from 'react-select'

export function CommonSelect({name,formData,options,handleValue}) {
  const isError = formData.requiredError || formData.rangeError || formData.phoneFormatError || formData.notKyusyuError || formData.notFoundAddressError || formData.rangeErrorSubmit || formData.correlationRequiredError

  const border = isError ? "#f44336 solid 1px" : "#b5c6d2 solid 1px";
  const backgroundColor = isError ? "#feeceb" : "";

  const customStyles = {
    control: () => (
      {
      // none of react-select's styles are passed to <Control />
　　　 //ここでボックス自体のスタイルをカスタマイズ
      width: "100%",
      background: "#fff",
      borderRadius: "4px",
      letterSpacing: "0.075em",
      position: "absolute",
      border: border,
      backgroundColor: backgroundColor,
    }),
  };

  function getLabel(){
    let resultObject = options.find(option => option.value === formData.value);
    return resultObject ? resultObject.label : ""
  }

  function handleSelectChange(e) {
    handleValue(name,e.value)
  }

  return (

            <Select 
              options={options}
              value={{label:getLabel(),value:formData.value}}
              placeholder=""
              onChange={(e) => {handleSelectChange(e)}}
              styles={customStyles}
              isSearchable={false}
              className="select"
              classNamePrefix="rs"
              components={{
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null,
              }}
            />
          
    
  );
}
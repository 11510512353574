import React, { useEffect } from "react";

import { FORM_C001 } from "../config/form";
import { displayName } from "../config/config";
import {required,isShortRange,correlationRequired} from "../util/validate";
import { convertMinDate,call_api,serchSiKuTyouSon,serchAzaTyouMe,getPostJsonData,addressSearchFromPostNum,isOperater,updateValues} from "../util/util";
import { allTodouHuKen} from "../config/address";
import { Layout } from "../Layout/Layout";
import { FormInputWrapper } from "../Layout/FormInputWrapper";
import { SectionWithText } from "../Layout/SectionWithText";
import {TermInputPair} from "../Layout/TermInputPair"
import { InputText } from "../parts/InputText";
import { CustomSelect } from "../parts/CustomSelect";
import { ZipCodeSearch } from "../components/ZipCodeSearch";
import { FormValidationError } from "../components/FormValidationError";
import { RenderPhoneNumberInputs } from "../components/RenderPhoneNumberInputs";
import { Submit } from "../components/Submit";
import liff from '@line/liff';

export function C003(props) {
    document.title = displayName.C003;
    const room_id = props.roomId
    const [values, setValues] = React.useState(FORM_C001);
    const [postJsonData, setPostJsonData] = React.useState({});
    const [sikuTyouSonJsonData, setSikuTyouSonJsonData] = React.useState({});
    const [azaTyoumeJsonData, setAzaTyoumeJsonData] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(false);
    const [isSearchClick,setIsSearchClick] = React.useState(false);
    const [isSubmitDisabled,setIsSubmitDisabled] = React.useState(true);
    let errCount = 0
    useEffect(() => {
        getPostJsonData(setPostJsonData,setSikuTyouSonJsonData,setAzaTyoumeJsonData)
        fetchInitialData(room_id)
      },[]);

    //住所データ三つを監視対象としているが同期処理で返却されるためいずれも同一タイミングで変更検知
    useEffect(() => {
        if(Object.keys(postJsonData).length){
            handleValue("siKuTyouSonOption",serchSiKuTyouSon(values.toDouHuKen.value,sikuTyouSonJsonData))
            handleValue("azaTyoumeOption",serchAzaTyouMe(values.toDouHuKen.value+values.siKuTyouSon.value,azaTyoumeJsonData))
            if(isSearchClick){
                searchClick(values.yubinBangou.value)
                setIsLoading(false)
            }
        } 
    }, [postJsonData,sikuTyouSonJsonData,azaTyoumeJsonData]);

    //初期表示処理
    async function fetchInitialData(_room_id){
        let form = {"room_id":_room_id,}
        let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo",form)
        if(result.status_code==="200"){
            await setIsSubmitDisabled(!isOperater(result.roomInfo))
        }
    }

    //郵便番号検索
    function searchClick(){
        const yubinBangou = values.yubinBangou.value;
        const isRequiredError = required(yubinBangou);
        const isRangeError = isShortRange(yubinBangou, 7);
        handleError("yubinBangou","requiredError",isRequiredError)
        handleError("yubinBangou","rangeError",isRangeError)
    
        if(isRequiredError || isRangeError)return;
        if(!(Object.keys(postJsonData).length)) {setIsSearchClick(true);setIsLoading(true);return;}
    
        //郵便番号で検索
        const address = addressSearchFromPostNum(yubinBangou,postJsonData,azaTyoumeJsonData)
        const notFound = Object.keys(address).length === 0    
        handleError("yubinBangou","notFoundAddressError",notFound)
        if(notFound)return;

        tdhChange("toDouHuKen", address.prefecture_name)
        sktsChange("siKuTyouSon",address.municipalitie_name,address.prefecture_name)
        handleValue("azaTyoumeOption",address.addressList)
    }

    //送信処理
    async function submit(){
        //入力チェック
        errCount = 0
        handleError("henkouYoteibi","requiredError",required(values.henkouYoteibi.value))
        handleError("yubinBangou","rangeErrorSubmit",isShortRange(values.yubinBangou.value,7))
        handleError("toDouHuKen","requiredError",required(values.toDouHuKen.value))
        handleError("siKuTyouSon","requiredError",required(values.siKuTyouSon.value))
        handleError("azaTyoume","requiredError",required(values.azaTyoume.value))
        const handleCorrelationError = (prefix, count, correlationValues) => {
            for (let i = 1; i <= count; i++) {
                const fieldName = `${prefix}${i}`;
                handleError(fieldName, "requiredError", correlationRequired(values[fieldName].value, correlationValues));
            }
        };
        let henkougoDenwaBangou = values.henkougoDenwaBangou1.value + values.henkougoDenwaBangou2.value + values.henkougoDenwaBangou3.value
        handleCorrelationError("henkougoDenwaBangou", 3,henkougoDenwaBangou)
        handleError("yubinBangou","requiredError",false)
        handleError("yubinBangou","rangeError",false)
        handleError("yubinBangou","notFoundAddressError",false)
        handleError("yubinBangou","notKyusyuError",false)
        window.scrollTo({top: 0,behavior: 'smooth',})
        if(errCount !== 0){return;}

        //API連携
        let form = {
            "henkouYoteibi":values.henkouYoteibi.value,
            "yubinBangou":values.yubinBangou.value,
            "toDouHuKen":values.toDouHuKen.label,
            "siKuTyouSon":values.siKuTyouSon.label,
            "azaTyoume":values.azaTyoume.label,
            "banTiGou":values.bantiGou.value,
            "tatemonoMei":values.tatemonoMei.value,
            "heyaBangou":values.heyaBangou.value,
            "henkouGonoSiharaiBasyoDenwaBangou":String(values.henkougoDenwaBangou1.value) + "-" + String(values.henkougoDenwaBangou2.value) + "-" + String(values.henkougoDenwaBangou3.value),
            "display_id":"C003",
            "room_id":room_id
            }
        let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB002",form)
        if(result.status_code === "200"){
            setValues({ ...values, "systemError": false })
            liff.closeWindow()
            window.close()
        }else{
            setValues({ ...values, "systemError": true });
        }
    }

    //以下ハンドラー
    function handleCalendarBlur(e) {
        if(e.target.value === "") return;
        const date = convertMinDate(e.target.value,values[e.target.name].min)
        handleValue(e.target.name,date)
    }
    function handleCalendarChange(e) {
        handleValue(e.target.name,e.target.value)
        setTimeout(()=>{
            e.target.defaultValue = "";
          }, 100);   
    }
    function handleCalendarClick(e) {
        e.target.defaultValue = "";
    }
    function tdhChange(name,value) {
        let findTdhk = allTodouHuKen.find(item => item.value === value)
        const resetSiKuTyouSonAndAzaTyoume = () => {
            handleValue("siKuTyouSon", "");
            handleLabel("siKuTyouSon", "");
            handleValue("azaTyoume", "");
            handleLabel("azaTyoume", "");
        };
        handleValue(name,value)
        handleLabel(name,value === "" ? value :findTdhk.label)
        resetSiKuTyouSonAndAzaTyoume()
        handleValue("siKuTyouSonOption",serchSiKuTyouSon(value,sikuTyouSonJsonData))
    }
    function sktsChange(name,value,tdhkValue=values.toDouHuKen.value) {
        let findSts = sikuTyouSonJsonData[tdhkValue].find(item => item[1] === value)
        handleValue(name,value)
        handleLabel(name,value === "" ? value :findSts[0])
        handleValue("azaTyoume","")
        handleLabel("azaTyoume","")
        handleValue("azaTyoumeOption",serchAzaTyouMe(tdhkValue+value,azaTyoumeJsonData))
    }
    function atmChange(name,value) {
        let findAtm = azaTyoumeJsonData[values.toDouHuKen.value+values.siKuTyouSon.value].find(item => item[1] === value)
        handleValue(name,value)
        handleLabel(name,value === "" ? value :findAtm[0])
    }
    function handleValue(name, value) {
        updateValues({ [name]: { value } },setValues);
    }
    function handleLabel(name, value) {
        updateValues({ [name]: { label: value } },setValues);
    }
    function handleError(name, error, bool) {
        const updatedValues = { [name]: { [error]: bool } };
        if (bool) errCount += 1;
        updateValues(updatedValues,setValues);
    }

    
    
    return (
    <>
    <Layout isLoading={isLoading} label="検索中..." headerLabel={displayName.C003}>
        <SectionWithText label="以下の情報を入力ください。">
            <FormInputWrapper label="変更予定日" required={true} multi={true} calendar={true}>
                <p className="calendar">
                    <label className="calendar">
                        <input type="date" className={values.henkouYoteibi.requiredError ? "is-invalid" :""} onChange={handleCalendarChange} onBlur={handleCalendarBlur} onClick={handleCalendarClick} value={values.henkouYoteibi.value} name="henkouYoteibi" min={values.henkouYoteibi.min} />
                    </label>
                </p>
                <FormValidationError formDataList={[values.henkouYoteibi]} type="calendar"></FormValidationError>
            </FormInputWrapper>
            <FormInputWrapper label="変更後の送付先住所">
                <TermInputPair label="郵便番号" annotation="※ハイフンなしで検索ください" className="zip">
                    <ZipCodeSearch name="yubinBangou" formData={values.yubinBangou} searchClick={searchClick} handleValue={handleValue}></ZipCodeSearch>
                </TermInputPair>
                <FormValidationError formDataList={[values.yubinBangou]} type={"yubinBangou"}></FormValidationError>
                <dl>
                    <div className="explanation-box"><p className="explanation-box_txt">検索できない場合は、以下を直接入力してください</p></div>
                </dl>
                <TermInputPair label="都道府県" required={true}>
                    <CustomSelect name="toDouHuKen"options={allTodouHuKen} formData={values.toDouHuKen} handleValue={tdhChange} disabled={false}/>
                </TermInputPair>
                <FormValidationError formDataList={[values.toDouHuKen]} type={"addressSelect"}></FormValidationError>
                <TermInputPair label="市区町村" required={true}>
                    <CustomSelect name="siKuTyouSon"options={values.siKuTyouSonOption.value} formData={values.siKuTyouSon} handleValue={sktsChange} disabled={values.toDouHuKen.value === ""} />
                </TermInputPair>
                <FormValidationError formDataList={[values.siKuTyouSon]} type={"addressSelect"}></FormValidationError>
                <TermInputPair label="字丁目" required={true}>
                    <CustomSelect name="azaTyoume"options={values.azaTyoumeOption.value} formData={values.azaTyoume} handleValue={atmChange} disabled={values.siKuTyouSon.value === ""} />
                </TermInputPair>
                <FormValidationError formDataList={[values.azaTyoume]} type={"addressSelect"}></FormValidationError>
                <TermInputPair label="番地号">
                    <InputText name="bantiGou" formData={values.bantiGou} handleValue={handleValue}></InputText>
                </TermInputPair>
                <TermInputPair label="建物名">
                    <InputText name="tatemonoMei" formData={values.tatemonoMei} handleValue={handleValue}></InputText>
                </TermInputPair>
                <TermInputPair label="部屋番号">
                    <InputText name="heyaBangou" formData={values.heyaBangou} handleValue={handleValue}></InputText>
                </TermInputPair>
                <p className="annotation">マンション・アパートの場合は、建物名、部屋番号を必ず入力してください</p>
            </FormInputWrapper>
            <FormInputWrapper label="変更後の送付先住所電話番号" multi={true}>
                <p><RenderPhoneNumberInputs
                formDataList={[values.henkougoDenwaBangou1,values.henkougoDenwaBangou2,values.henkougoDenwaBangou3]}
                handleValue={handleValue}
                name="henkougoDenwaBangou"
                >
                </RenderPhoneNumberInputs></p>
                <FormValidationError formDataList={[values.henkougoDenwaBangou1,values.henkougoDenwaBangou2,values.henkougoDenwaBangou3]}></FormValidationError>
                <p className="annotation">変更がある場合のみ入力ください</p>
            </FormInputWrapper>
        </SectionWithText>
        <Submit label="送信" onClick={submit} error={values.systemError} disabled={isSubmitDisabled}></Submit>
    </Layout>
    </>
    )}
import React from 'react';
import Loading from './Loading';
import {Header} from './Header';
import {Footer} from './Footer';

export function Layout({ children,isLoading,label,headerLabel}){
  return (
    <div>
      {isLoading && <Loading text={label} />}
      <Header label={headerLabel} />
      <div id="form">
          {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
import React, { useEffect } from "react";
import { FORM_B001 } from "../config/form";
import { displayName } from "../config/config";
import {required,isDuplication} from "../util/validate";
import { convertMinDate,convertMaxDate,call_api,updateValues,isBot} from "../util/util";
import { Layout } from "../Layout/Layout";
import { FormInputWrapper } from "../Layout/FormInputWrapper";
import { SectionWithText } from "../Layout/SectionWithText";
import { FormValidationError } from "../components/FormValidationError";
import { Submit } from "../components/Submit";
import liff from '@line/liff';

export function B001(props) {
    document.title = displayName.B001;
    const room_id = props.roomId
    const [values, setValues] = React.useState(FORM_B001);
    const [fetchInitialDataFinish,setFetchInitialDataFinish] = React.useState(false);
    const [disableSubmit,setDisableSubmit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    let errCount = 0

    useEffect(() => {
        fetchInitialData(props.roomId);
    },[]);

    //初期表示処理
    async function fetchInitialData(_room_id){
        let form = {"room_id":_room_id,}
        let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo",form)
        if(result.status_code==="200"){
            await setDisableSubmit(!isBot(result.roomInfo))
        }
        await setFetchInitialDataFinish(true)
        return
    }

    //送信処理
    async function submit(){
        //入力チェック
        errCount = 0
        await setDisableSubmit(true)
        await setLoading(true)
        handleError("taisyouTuki1","requiredError",required(values.taisyouTuki1.value))
        handleError("taisyouTuki2","duplicationError",isDuplication(values.taisyouTuki1.value,values.taisyouTuki2.value))
        window.scrollTo({top: 0,behavior: 'smooth',})

        //API連携
        if(errCount === 0){
            //API連携
            let form = {
                "taisyouTuki1":values.taisyouTuki1.value,
                "taisyouTuki2":values.taisyouTuki2.value,
                "display_id":"B001",
                "room_id":room_id
                }
                let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB006",form)
            if(result.status_code === "200"){
                setValues({ ...values, "systemError": false })
                liff.closeWindow()
                window.close()
            }else{
                setValues({ ...values, "systemError": true });
            }
        }
        await setDisableSubmit(false)
        await setLoading(false)
    }

    //以下ハンドラー
    function handleCalendarBlur(e) {
        if(e.target.value === "") return;
        let date = convertMinDate(e.target.value,values[e.target.name].min)
        date = convertMaxDate(date,values[e.target.name].max)
        handleValue(e.target.name,date)
    }
    function handleCalendarChange(e) {
        handleValue(e.target.name,e.target.value)
        setTimeout(()=>{
            e.target.defaultValue = "";
        }, 100);
    }
    function handleCalendarClick(e) {
        e.target.defaultValue = "";
    }
    function handleValue(name, value) {
        updateValues({ [name]: { value } },setValues);
      }
    function handleError(name, error, bool) {
        const updatedValues = { [name]: { [error]: bool } };
        if (bool) errCount += 1;        
        updateValues(updatedValues,setValues);
    }


    return (
    <>
        {fetchInitialDataFinish && <Layout headerLabel={displayName.B001} isLoading={loading} label="送信中">
            <SectionWithText label={"確認を希望する対象月を選択してください。\n最大で2か月分の選択が可能です。"}>
                <FormInputWrapper label="対象年月1" required={true} multi={true} calendar={true}>
                    <p className="calendar">
                        <label className="calendar">
                            <input id="aaa" type="month" className={values.taisyouTuki1.requiredError ? "is-invalid" :""} onChange={handleCalendarChange} onClick={handleCalendarClick} onBlur={handleCalendarBlur} value={values.taisyouTuki1.value} name="taisyouTuki1" max={values.taisyouTuki1.max} min={values.taisyouTuki1.min} />
                        </label>
                    </p>
                    <FormValidationError formDataList={[values.taisyouTuki1]} type="calendar"></FormValidationError>
                </FormInputWrapper>
                <FormInputWrapper label="対象年月2" multi={true} calendar={true}>
                    <p className="calendar">
                        <label className="calendar">
                            <input type="month" className={values.taisyouTuki2.duplicationError ? "is-invalid" :""} onChange={handleCalendarChange} onClick={handleCalendarClick} onBlur={handleCalendarBlur} value={values.taisyouTuki2.value} name="taisyouTuki2" max={values.taisyouTuki2.max} min={values.taisyouTuki2.min} />
                        </label>
                    </p>
                    <FormValidationError formDataList={[values.taisyouTuki2]} type="calendar"></FormValidationError>
                </FormInputWrapper>
            </SectionWithText>
            <Submit label="送信" onClick={submit} error={values.systemError} disabled={disableSubmit}></Submit>
        </Layout>}
    </>
    )}
import React from "react";
import { InputTel } from "../parts/InputTel";

export function RenderPhoneNumberInputs({formDataList=[],handleValue,name,disabled=false}) {

      return (
        <>
            {formDataList.map((input, index) => (
                <React.Fragment key={input.name+String(index)}>
                    <InputTel
                        name={name+String(index+1)}
                        formData={input}
                        handleValue={handleValue}
                        className="fg01"
                        placeHolder={(index === 0) ? "例）090" : ((index === 1) ? "1234" : "5678")}
                        disabled={disabled}
                    >
                    </InputTel>
                    {(index !== formDataList.length - 1) && <span>-</span>}
                </React.Fragment>
            ))}
        </>
    )
}
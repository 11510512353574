import React from "react";

export function InputRadio({name,radioInfo,formData,handleValue}) {
    const selectedOption = formData.value;
    function handleRadioChange(e) {
        handleValue(e.target.name,e.target.value)
    }
    return (
        <>
        {radioInfo.map((info) => (
            <React.Fragment key={info.id}>
            <input type="radio"
                name={name}
                value={info.value}
                onChange={handleRadioChange}
                id={info.id}
                checked={info.value===selectedOption} />
            <label htmlFor={info.id}>{info.label}</label>
            </React.Fragment>
        ))}
        </>
    );
}
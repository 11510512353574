import React, { useEffect } from "react";
import { FORM_A004 } from "../config/form";
import {isShortRange,isKana} from "../util/validate";
import {call_api,updateValues} from "../util/util";
import {displayName } from "../config/config";
import { Layout } from "../Layout/Layout";
import { Submit } from "../components/Submit";
import { SectionWithText } from "../Layout/SectionWithText";
import { InputText } from "../parts/InputText";
import { InputTel } from "../parts/InputTel";
import { FormInputWrapper } from "../Layout/FormInputWrapper";
import { FormValidationError } from "../components/FormValidationError";
import liff from '@line/liff';

export function A004(props) {
  document.title = displayName.A004;
  const [values, setValues] = React.useState(FORM_A004);
  const room_id = props.roomId
  const [fetchInitialDataFinish,setFetchInitialDataFinish] = React.useState(false);
  const [isSubmitDisabled,setIsSubmitDisabled] = React.useState(false);
  let errCount = 0

  useEffect(() => {
    fetchInitialData(props.roomId);
  },[]);

  //初期表示処理
  async function fetchInitialData(_room_id) {
    let form = { "room_id": _room_id }
    let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo", form)
    if (result.status_code !== "200" || !result?.extended_room_tag_info?.keiki_info){setFetchInitialDataFinish(true);return;} 

    if(result.A00234DefaultViewSetting.is_show){
      let [title, keikiBangou, dentyuBangou] = result.extended_room_tag_info.keiki_info.split('\n').map(item => item.split(":")[1]);
      handleValue("keikiBangou", keikiBangou);
      handleValue("dentyuBangou1", dentyuBangou.substring(0,3));
      handleValue("dentyuBangou2", dentyuBangou.substring(3,4));
      handleValue("dentyuBangou3", dentyuBangou.substring(4,7));
    }
    setIsSubmitDisabled(!(result.A00234DefaultViewSetting.is_submit))
    await setFetchInitialDataFinish(true);
  }

  //送信処理
  async function submit(){
    errCount = 0
    //入力チェック
    handleError("keikiBangou","rangeError",isShortRange(values.keikiBangou.value,7))
    handleError("dentyuBangou1","requiredError",values.dentyuBangou1.value === "" && (values.dentyuBangou2.value !== "" || values.dentyuBangou3.value !== ""))
    handleError("dentyuBangou1","rangeError",isShortRange(values.dentyuBangou1.value,3))
    handleError("dentyuBangou2","requiredError",values.dentyuBangou2.value === "" && (values.dentyuBangou1.value !== "" || values.dentyuBangou3.value !== ""))
    handleError("dentyuBangou2","kanaError",isKana(values.dentyuBangou2.value))
    handleError("dentyuBangou3","requiredError",values.dentyuBangou3.value === "" && (values.dentyuBangou1.value !== "" || values.dentyuBangou2.value !== ""))
    handleError("dentyuBangou3","rangeError",isShortRange(values.dentyuBangou3.value,3))
    window.scrollTo({top: 0,behavior: 'smooth',})

    if(errCount === 0){
      //API連携
      let form = {
        "keikiBangou":values.keikiBangou.value,
        "dentyuBangou":String(values.dentyuBangou1.value)+String(values.dentyuBangou2.value)+String(values.dentyuBangou3.value),
        "display_id":"A004",
        "room_id":room_id
      }
      let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB002",form)
      if(result.status_code === "200"){
          setValues({ ...values, "systemError": false })
          liff.closeWindow()
          window.close()
      }else{
          setValues({ ...values, "systemError": true });
      }
    }
  }

  //以下ハンドラー
  function handleValue(name, value) {
    updateValues({ [name]: { value } },setValues);
  }
  function handleError(name, error, bool) {
    const updatedValues = { [name]: { [error]: bool } };
    if (bool) errCount += 1;
    updateValues(updatedValues,setValues);
  }

  return (
    <>
    {fetchInitialDataFinish && <Layout isLoading={false} headerLabel={displayName.A004}>
      <SectionWithText label="以下の情報が確認可能な場合、ご入力ください。">
        <FormInputWrapper label="計器番号（7桁）">
          <p><InputTel name="keikiBangou" formData={values.keikiBangou} handleValue={handleValue}></InputTel></p>
          <FormValidationError formDataList={[values.keikiBangou]}></FormValidationError>
        </FormInputWrapper>
        <FormInputWrapper label="電柱番号" multi={true}>
        <div className="w40">
          <p>
            <InputTel name="dentyuBangou1" formData={values.dentyuBangou1} handleValue={handleValue} className="fg03"></InputTel><span>-</span>
            <InputText name="dentyuBangou2" formData={values.dentyuBangou2} handleValue={handleValue} className="fg01"></InputText><span>-</span>
            <InputTel name="dentyuBangou3" formData={values.dentyuBangou3} handleValue={handleValue} className="fg03"></InputTel>
          </p>
          <p className="annotation txt_placeholder">
              <span className="fg03">数字3桁</span>
              <span className="fg01">カナ1桁</span>
              <span className="fg03">数字3桁</span>
          </p>
          <FormValidationError formDataList={[values.dentyuBangou1,values.dentyuBangou2,values.dentyuBangou3]}></FormValidationError>
          </div>
        </FormInputWrapper>
      </SectionWithText>
      <Submit label="送信" onClick={submit} error={values.systemError} disabled={isSubmitDisabled}></Submit>
    </Layout>}
    </>
  );
}
import React, { useEffect } from "react";
import { FORM_C001 } from "../config/form";
import { displayName } from "../config/config";
import {required} from "../util/validate";
import {call_api,updateValues,isBot} from "../util/util";
import { Layout } from "../Layout/Layout";
import { FormInputWrapper } from "../Layout/FormInputWrapper";
import { SectionWithText } from "../Layout/SectionWithText";
import { CommonSelect } from "../parts/CommonSelect";
import { FormValidationError } from "../components/FormValidationError";
import { Submit } from "../components/Submit";
import liff from '@line/liff';

export function C006(props) {
  document.title = displayName.C006;
  const room_id = props.roomId
  const [values, setValues] = React.useState(FORM_C001);
  const [reissueOption,setReissueOption] = React.useState([]);
  const [fetchInitialDataFinish,setFetchInitialDataFinish] = React.useState(false);
  const [isLoading,setIsLoading] = React.useState(false);
  const [isSubmitDisabled,setIsSubmitDisabled] = React.useState(false);
  let errCount = 0

  useEffect(() => {
    fetchInitialData(props.roomId)
  },[]);

  //初期表示処理
  async function fetchInitialData(_room_id){
      let form = {"room_id":_room_id,}
      let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB008",form)
      if(result.status_code==="200"){
        if(result.api_error){
          liff.closeWindow()
          window.close()
          return
        }
        if(result.reissue_list.length===0){
          alert("該当する請求情報がありませんでした。")
          liff.closeWindow()
          window.close()
          return
        }

        await setReissueOption(result.reissue_list)
        await setIsSubmitDisabled(!isBot(result.roomInfo))
      }
      setFetchInitialDataFinish(true)
      return result
  }

  //送信処理
  async function submit(){
    //入力チェック
    errCount = 0
    handleError("taisyouTuki1","requiredError",required(values.taisyouTuki1.value))
    window.scrollTo({top: 0,behavior: 'smooth',})
    const matchedItem = reissueOption.filter(item => parseInt(item.value) <= parseInt(values.taisyouTuki1.value));
    //API連携
    if(errCount === 0){
        await setIsLoading(true)
        //API連携
        let form = {
            "reissueList":matchedItem,
            "display_id":"C006",
            "room_id":room_id
          }
        let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB009",form)
        if(result.status_code === "200"){
            setValues({ ...values, "systemError": false })
            liff.closeWindow()
            window.close()
        }else{
            setValues({ ...values, "systemError": true });
        }
    }
    await setIsLoading(false)
  }

  //以下ハンドラー
  function handleValue(name, value) {
    updateValues({ [name]: { value } },setValues);
  }
  function handleError(name, error, bool) {
    const updatedValues = { [name]: { [error]: bool } };
    if (bool) errCount += 1;    
    updateValues(updatedValues,setValues);
  }

  return (
    <>
    {fetchInitialDataFinish && <Layout isLoading={isLoading} label="送信中" headerLabel={displayName.C006}>
        <SectionWithText label="以下の情報を入力ください。">
            <FormInputWrapper label="対象年月分" required={true}>
                <p className="annotation">ご希望の対象年月を選択してください。</p>
                <CommonSelect name="taisyouTuki1" options={reissueOption} formData={values.taisyouTuki1} handleValue={handleValue} />
                <FormValidationError formDataList={[values.taisyouTuki1]} type="select"></FormValidationError>

            </FormInputWrapper>
            <p className="annotation">までの請求書（SMS）を発行する。</p>
        </SectionWithText>
        <Submit label="送信" onClick={submit} error={values.systemError} disabled={isSubmitDisabled}></Submit>
    </Layout>}
    </>
  )
}
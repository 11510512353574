import './App.css';
import { A001 } from "./pages/A001";
import { A002 } from "./pages/A002";
import { A003 } from "./pages/A003";
import { A004 } from "./pages/A004";
import { B001 } from "./pages/B001";
import { B002 } from "./pages/B002";
import { C001 } from "./pages/C001";
import { C002 } from "./pages/C002";
import { C003 } from "./pages/C003";
import { C004 } from "./pages/C004";
import { C005 } from "./pages/C005";
import { C006 } from "./pages/C006";
import { C007 } from "./pages/C007";
import { D001 } from "./pages/D001";
import { E001 } from "./pages/E001";
import { G001 } from "./pages/G001";
import { K001 } from "./pages/K001";
import { NoMatch } from "./pages/NoMatch";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  } from "react-router-dom";


function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2]);
}

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" />
          <Route path="/gokeiyakuJouhou" element={<A001 roomId={getParam("roomid")} />} />
          <Route path="/denkiRyoukinOsiharaiJouhou" element={<A002 roomId={getParam("roomid")} />} />
          <Route path="/osiharaisyaJouhou" element={<A003 roomId={getParam("roomid")} />} />
          <Route path="/denkiSetubiJouhou" element={<A004 roomId={getParam("roomid")} />} />
          <Route path="/kigenHurikaebiKakunin" element={<B001 roomId={getParam("roomid")} />} />
          <Route path="/siharaiJouKyouNoKakunin" element={<B002 roomId={getParam("roomid")} />} />
          <Route path="/meigiHenkou" element={<C001 roomId={getParam("roomid")} />} />
          <Route path="/gotourokunoDenwaBangouHenkou" element={<C002 roomId={getParam("roomid")} />} />
          <Route path="/yubinbutunoSouhusakiJusyoHenkou" element={<C003 roomId={getParam("roomid")} />} />
          <Route path="/siyouRyounoOsiraseHakkouMatahaTeisi" element={<C004 roomId={getParam("roomid")} />} />
          <Route path="/denkigosiyouryounoOsirasenoSaiHakkou" element={<C005 roomId={getParam("roomid")} />} />
          <Route path="/hurikomiHyouSaiHakkou" element={<C006 roomId={getParam("roomid")} />} />
          <Route path="/gosiyouKaisi" element={<C007 roomId={getParam("roomid")} />} />
          <Route path="/freeNyuryoku" element={<D001 roomId={getParam("roomid")} />} />
          <Route path="/authCodeNyuryoku" element={<E001 roomId={getParam("roomid")} />} />
          <Route path="/keitaiBangouTeisei" element={<G001 roomId={getParam("roomid")} />} />
          <Route path="/freeKaitou" element={<K001 roomId={getParam("roomid")} answer={getParam("answer")} />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import { FORM_C001 } from "../config/form";
import {displayName } from "../config/config";
import {required} from "../util/validate";
import {convertMinDate,call_api,isOperater,updateValues} from "../util/util";
import { Layout } from "../Layout/Layout";
import { FormInputWrapper } from "../Layout/FormInputWrapper";
import { SectionWithText } from "../Layout/SectionWithText";
import { FormValidationError } from "../components/FormValidationError";
import { RenderPhoneNumberInputs } from "../components/RenderPhoneNumberInputs";
import { Submit } from "../components/Submit";
import liff from '@line/liff';

export function C002(props) {
    document.title = displayName.C002;
    const room_id = props.roomId;
    const [values, setValues] = React.useState(FORM_C001);
    const [isSubmitDisabled,setIsSubmitDisabled] = React.useState(true);
    let errCount = 0

    useEffect(() => {
        fetchInitialData(props.roomId)
    },[]);

    //初期表示処理
    async function fetchInitialData(_room_id){
        let form = {"room_id":_room_id,}
        let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo",form)
        if(result.status_code==="200"){
            await setIsSubmitDisabled(!isOperater(result.roomInfo))
        }
    }

    //送信処理
    async function submit(){
        //入力チェック
        errCount = 0
        handleError("henkougoDenwaBangou1","requiredError",required(values.henkougoDenwaBangou1.value))
        handleError("henkougoDenwaBangou2","requiredError",required(values.henkougoDenwaBangou2.value))
        handleError("henkougoDenwaBangou3","requiredError",required(values.henkougoDenwaBangou3.value))
        handleError("henkouYoteibi","requiredError",required(values.henkouYoteibi.value))
        window.scrollTo({top: 0,behavior: 'smooth',})

        //API連携
        if(errCount !== 0){return;}
        let form = {
            "henkouGoDenwaBangou":String(values.henkougoDenwaBangou1.value) + "-" + String(values.henkougoDenwaBangou2.value) + "-" + String(values.henkougoDenwaBangou3.value),
            "henkouYoteibi":values.henkouYoteibi.value,
            "display_id":"C002",
            "room_id":room_id
            }
        let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB002",form)
        if(result.status_code === "200"){
            setValues({ ...values, "systemError": false })
            liff.closeWindow()
            window.close()
        }else{
            setValues({ ...values, "systemError": true });
        }
    }

    //以下ハンドラー
    function handleCalendarBlur(e) {
        if(e.target.value === "") return;
        const date = convertMinDate(e.target.value,values[e.target.name].min)
        handleValue(e.target.name,date)
    }
    function handleCalendarChange(e) {
        handleValue(e.target.name,e.target.value)
        setTimeout(()=>{
            e.target.defaultValue = "";
          }, 100);
    }
    function handleCalendarClick(e) {
        e.target.defaultValue = "";
    }
    function handleValue(name, value) {
        updateValues({ [name]: { value } },setValues);
      }
    function handleError(name, error, bool) {
        const updatedValues = { [name]: { [error]: bool } };
        if (bool) errCount += 1;
        updateValues(updatedValues,setValues);
    }

    return (
    <>
    <Layout headerLabel={displayName.C002}>
        <SectionWithText label="以下の情報を入力ください。">
            <FormInputWrapper label="変更後電話番号" required={true} multi={true}>
                <p><RenderPhoneNumberInputs
                    formDataList={[values.henkougoDenwaBangou1,values.henkougoDenwaBangou2,values.henkougoDenwaBangou3]}
                    handleValue={handleValue}
                    name="henkougoDenwaBangou"
                >
                </RenderPhoneNumberInputs></p>
                <FormValidationError formDataList={[values.henkougoDenwaBangou1,values.henkougoDenwaBangou2,values.henkougoDenwaBangou3]}></FormValidationError>
            </FormInputWrapper>
            <FormInputWrapper label="変更予定日" required={true} multi={true} calendar={true}>
                <p className="calendar">
                    <label className="calendar">
                        <input type="date" className={values.henkouYoteibi.requiredError ? "is-invalid" :""} onChange={handleCalendarChange} onBlur={handleCalendarBlur} onClick={handleCalendarClick} value={values.henkouYoteibi.value} name="henkouYoteibi" min={values.henkouYoteibi.min} />
                    </label>
                </p>
                <FormValidationError formDataList={[values.henkouYoteibi]} type="calendar"></FormValidationError>
            </FormInputWrapper>
        </SectionWithText>
        <Submit label="送信" onClick={submit} error={values.systemError} disabled={isSubmitDisabled}></Submit>
    </Layout>
    </>
    )}
import React, { useEffect } from "react";
import {InputTel} from "../parts/InputTel"

export function ZipCodeSearch({name,formData,searchClick,handleValue}) {

  return (
      <>
          <InputTel name={name} formData={formData} handleValue={handleValue} p={false} notErrorWrap={false}></InputTel>
          <input type="button" value="検索" onClick={() => searchClick()} className="zipSearchBtn" />
      </>
  );
}
import React, { useEffect } from "react";
import { FORM_C007 } from "../config/form";
import { osiharaiHouhou,C007_mousikomiSyaKubun,displayName } from "../config/config";
import {required,isShortRange,isKana,isZenkaku} from "../util/validate";
import { CustomSelect } from "../parts/CustomSelect";
import { CommonSelect } from "../parts/CommonSelect";
import { convertMinDate,call_api,serchSiKuTyouSon,serchAzaTyouMe,getPostJsonData,addressSearchFromPostNum,updateValues,isOperater} from "../util/util";
import { kyusyuTodouHuKen} from "../config/address";
import { Layout } from "../Layout/Layout";
import { FormInputWrapper } from "../Layout/FormInputWrapper";
import { SectionWithText } from "../Layout/SectionWithText";
import { InputText } from "../parts/InputText";
import { FormValidationError } from "../components/FormValidationError";
import {TermInputPair} from "../Layout/TermInputPair"
import { RenderPhoneNumberInputs } from "../components/RenderPhoneNumberInputs";
import { ZipCodeSearch } from "../components/ZipCodeSearch";
import { Submit } from "../components/Submit";
import { kyusyuKenList,allTodouHuKen} from "../config/address";
import liff from '@line/liff';

export function C007(props) {
    document.title = displayName.C007;
    const room_id = props.roomId
    const [values, setValues] = React.useState(FORM_C007);
    const [postJsonData, setPostJsonData] = React.useState([]);
    const [sikuTyouSonJsonData, setSikuTyouSonJsonData] = React.useState([]);
    const [azaTyoumeJsonData, setAzaTyoumeJsonData] = React.useState([]);
    const [isSearchClick,setIsSearchClick] = React.useState(false);
    const [isLoading,setIsLoading] = React.useState(false);
    const [isSubmitDisabled,setIsSubmitDisabled] = React.useState(true);
    let errCount = 0
    useEffect(() => {
        getPostJsonData(setPostJsonData,setSikuTyouSonJsonData,setAzaTyoumeJsonData)
    },[]);

    useEffect(() => {
        fetchInitialData(room_id)
        if(Object.keys(postJsonData).length){
            handleValue("siKuTyouSonOption",serchSiKuTyouSon(values.toDouHuKen.value,sikuTyouSonJsonData))
            handleValue("azaTyoumeOption",serchAzaTyouMe(values.toDouHuKen.value+values.siKuTyouSon.value,azaTyoumeJsonData))
            if(isSearchClick){
                searchClick(values.yubinBangou.value)
                setIsLoading(false)
            }
        } 
    }, [postJsonData,sikuTyouSonJsonData,azaTyoumeJsonData]);

    //初期表示処理
    async function fetchInitialData(_room_id){
        let form = {"room_id":_room_id,}
        let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo",form)
        if(result.status_code==="200"){
            await setIsSubmitDisabled(!isOperater(result.roomInfo))
        }
        return result
    }

    //郵便番号検索
    function searchClick(){
        const yubinBangou = values.yubinBangou.value;
        const isRequiredError = required(yubinBangou);
        const isRangeError = isShortRange(yubinBangou, 7);
    
        handleError("yubinBangou","requiredError",isRequiredError)
        handleError("yubinBangou","rangeError",isRangeError)
    
        if(isRequiredError || isRangeError)return;
        if(!(Object.keys(postJsonData).length)) {setIsSearchClick(true);setIsLoading(true);return;}
    
        //郵便番号で検索
        const address = addressSearchFromPostNum(yubinBangou,postJsonData,azaTyoumeJsonData)
    
        const notFound = Object.keys(address).length === 0
        const isKyusyu = kyusyuKenList.includes(address.prefecture_name)
        handleError("yubinBangou","notFoundAddressError",notFound)
        handleError("yubinBangou","notKyusyuError",!notFound && !isKyusyu)
        if(!isKyusyu || notFound)return;
    
        tdhChange("toDouHuKen", address.prefecture_name)
        sktsChange("siKuTyouSon",address.municipalitie_name,address.prefecture_name)
        handleValue("azaTyoumeOption",address.addressList)
    }

    //送信処理
    async function submit(){
        //入力チェック
        errCount = 0
        Object.keys(values).forEach(fieldName => {
            const field = values[fieldName];
            if (field.hasOwnProperty("requiredError") && fieldName !== "yubinBangou") {
                handleError(fieldName, "requiredError", required(field.value));
            }
            if (field.hasOwnProperty("rangeError")) {
                handleError(fieldName, "rangeError", isShortRange(field.value, field.maxLength));
            }
            if (field.hasOwnProperty("kanaError") && fieldName !== "yubinBangou") {
                handleError(fieldName, "kanaError", isKana(field.value));
            }
            if (field.hasOwnProperty("hankakuError")) {
                handleError(fieldName, "hankakuError", isZenkaku(field.value));
            }
        });
        handleError("yubinBangou","requiredError",false)
        handleError("yubinBangou","rangeError",false)
        handleError("yubinBangou","notFoundAddressError",false)
        handleError("yubinBangou","notKyusyuError",false)
        //相関チェック
        handleError("keiyakuSyaTonoGokankei","correlationRequiredError",["家族", "その他"].includes(values.mousikomiSyaKbn.value) && required(values.keiyakuSyaTonoGokankei.value))

        handleError("yubinBangou","requiredError",false)
        handleError("yubinBangou","rangeError",false)
        handleError("yubinBangou","notFoundAddressError",false)
        handleError("yubinBangou","notKyusyuError",false)
        window.scrollTo({top: 0,behavior: 'smooth',})

        //API連携
        if(errCount === 0){
            let form = {
                "gosiyouKaisiBi":values.gosiyouKaisiBi.value,
                "yubinBangou":values.yubinBangou.value,
                "toDouHuKen":values.toDouHuKen.label,
                "siKuTyouSon":values.siKuTyouSon.label,
                "azaTyoume":values.azaTyoume.label,
                "banTiGou":values.bantiGou.value,
                "tatemonoMei":values.tatemonoMei.value,
                "heyaBangou":values.heyaBangou.value,
                "mousikomiSyaKbn":values.mousikomiSyaKbn.value,
                "keiyakuSyaTonoGokankei":values.keiyakuSyaTonoGokankei.value,
                "mousikomiSyaMeigi":values.mousikomiSyaMeigi.value,
                "keiyakuSyaMeigi":values.keiyakuMeigi.value,
                "keiyakuSyaMeigiKana":values.keiyakuMeigiKana.value,
                "denwaBangou":String(values.denwaBangou1.value) + "-" + String(values.denwaBangou2.value) + "-" + String(values.denwaBangou3.value),
                "osiharaiHouhou":values.osiharaiHouhou.value,
                "display_id":"C007",
                "room_id":room_id
            }
            let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB002",form)
            if(result.status_code === "200"){
                setValues({ ...values, "systemError": false })
                liff.closeWindow()
                window.close()
            }else{
                setValues({ ...values, "systemError": true });
            }
        }
    }


    //以下ハンドラー
    function handleCalendarBlur(e) {
        if(e.target.value === "") return;
        const date = convertMinDate(e.target.value,values[e.target.name].min)
        handleValue(e.target.name,date)
    }
    function handleCalendarChange(e) {
        handleValue(e.target.name,e.target.value)
        setTimeout(()=>{
            e.target.defaultValue = "";
        }, 100);
        
    }
    function handleCalendarClick(e) {
        e.target.defaultValue = "";
    }
    function tdhChange(name,value) {
        let findTdhk = allTodouHuKen.find(item => item.value === value)
        const resetSiKuTyouSonAndAzaTyoume = () => {
        handleValue("siKuTyouSon", "");
        handleLabel("siKuTyouSon", "");
        handleValue("azaTyoume", "");
        handleLabel("azaTyoume", "");
        };
        handleValue(name,value)
        handleLabel(name,value === "" ? value :findTdhk.label)
        resetSiKuTyouSonAndAzaTyoume()
        handleValue("siKuTyouSonOption",serchSiKuTyouSon(value,sikuTyouSonJsonData))
    }
    function sktsChange(name,value,tdhkValue=values.toDouHuKen.value) {
        let findSts = sikuTyouSonJsonData[tdhkValue].find(item => item[1] === value)
        handleValue(name,value)
        handleLabel(name,value === "" ? value :findSts[0])
        handleValue("azaTyoume","")
        handleLabel("azaTyoume","")
        handleValue("azaTyoumeOption",serchAzaTyouMe(tdhkValue+value,azaTyoumeJsonData))
    }
    function atmChange(name,value) {
        let findAtm = azaTyoumeJsonData[values.toDouHuKen.value+values.siKuTyouSon.value].find(item => item[1] === value)
        handleValue(name,value)
        handleLabel(name,value === "" ? value :findAtm[0])
    }
    function handleValue(name, value) {
        updateValues({ [name]: { value } },setValues);
    }
    function handleLabel(name, value) {
        updateValues({ [name]: { label: value } },setValues);
    }
    function handleError(name, error, bool) {
        const updatedValues = { [name]: { [error]: bool } };
        if (bool) errCount += 1;
        updateValues(updatedValues,setValues);
    }


    return (
    <>
    <Layout isLoading={isLoading} label="検索中..." headerLabel={displayName.C007}>
        <SectionWithText label="以下の情報を入力ください。">
            <FormInputWrapper label="ご使用開始日" required={true} multi={true} calendar={true}>
                <p className="calendar">
                    <label className="calendar">
                        <input type="date" className={values.gosiyouKaisiBi.requiredError ? "is-invalid" :""} onChange={handleCalendarChange} onClick={handleCalendarClick} onBlur={handleCalendarBlur} value={values.gosiyouKaisiBi.value} name="gosiyouKaisiBi" min={values.gosiyouKaisiBi.min} />
                    </label>
                </p>
                <FormValidationError formDataList={[values.gosiyouKaisiBi]} type="calendar"></FormValidationError>
            </FormInputWrapper>
            <FormInputWrapper label="ご使用場所の住所">
                <TermInputPair label="郵便番号" annotation="※ハイフンなしで検索ください" className="zip">
                    <ZipCodeSearch name="yubinBangou" formData={values.yubinBangou} searchClick={searchClick} handleValue={handleValue}></ZipCodeSearch>
                </TermInputPair>
                <FormValidationError formDataList={[values.yubinBangou]} type={"yubinBangou"}></FormValidationError>
                <dl>
                    <div className="explanation-box"><p className="explanation-box_txt">検索できない場合は、以下を直接入力してください</p></div>
                </dl>
                <TermInputPair label="都道府県" required={true}>
                    <CustomSelect name="toDouHuKen"options={kyusyuTodouHuKen} formData={values.toDouHuKen} handleValue={tdhChange} disabled={false}/>
                </TermInputPair>
                <FormValidationError formDataList={[values.toDouHuKen]} type={"addressSelect"}></FormValidationError>
                <TermInputPair label="市区町村" required={true}>
                    <CustomSelect name="siKuTyouSon"options={values.siKuTyouSonOption.value} formData={values.siKuTyouSon} handleValue={sktsChange} disabled={values.toDouHuKen.value === ""} />
                </TermInputPair>
                <FormValidationError formDataList={[values.siKuTyouSon]} type={"addressSelect"}></FormValidationError>
                <TermInputPair label="字丁目" required={true}>
                    <CustomSelect name="azaTyoume"options={values.azaTyoumeOption.value} formData={values.azaTyoume} handleValue={atmChange} disabled={values.siKuTyouSon.value === ""} />
                </TermInputPair>
                <FormValidationError formDataList={[values.azaTyoume]} type={"addressSelect"}></FormValidationError>
                <TermInputPair label="番地号">
                    <InputText name="bantiGou" formData={values.bantiGou} handleValue={handleValue}></InputText>
                </TermInputPair>
                <TermInputPair label="建物名">
                    <InputText name="tatemonoMei" formData={values.tatemonoMei} handleValue={handleValue}></InputText>
                </TermInputPair>
                <TermInputPair label="部屋番号">
                    <InputText name="heyaBangou" formData={values.heyaBangou} handleValue={handleValue}></InputText>
                </TermInputPair>
                <p className="annotation">マンション・アパートの場合は、建物名、部屋番号を必ず入力してください</p>
            </FormInputWrapper>
            <FormInputWrapper label="申込者情報を入力してください">
                <dl>
                    <div className=""><p className="">1 契約者とのご関係を選択してください。</p></div>
                </dl>
                <TermInputPair label="申込者区分" required={true}>
                    <CommonSelect name="mousikomiSyaKbn" options={C007_mousikomiSyaKubun} formData={values.mousikomiSyaKbn} handleValue={handleValue} />
                </TermInputPair>
                <FormValidationError formDataList={[values.mousikomiSyaKbn]} type="select"></FormValidationError>
                {["家族", "その他"].includes(values.mousikomiSyaKbn.value) && <>
                    <TermInputPair label="契約者とのご関係" required={true}>
                        <InputText name="keiyakuSyaTonoGokankei" formData={values.keiyakuSyaTonoGokankei} handleValue={handleValue}></InputText>
                    </TermInputPair>
                    <FormValidationError formDataList={[values.keiyakuSyaTonoGokankei]}></FormValidationError> </>
                }
                <dl>
                    <div className=""><p className="">2 申込者のお名前を入力してください。</p></div>
                </dl>
                <TermInputPair label="申込者名義" required={true}>
                    <InputText name="mousikomiSyaMeigi" formData={values.mousikomiSyaMeigi} handleValue={handleValue}></InputText>
                </TermInputPair>
                <FormValidationError formDataList={[values.mousikomiSyaMeigi]}></FormValidationError>
            </FormInputWrapper>
            <FormInputWrapper label="契約者名義（漢字）" required={true}>
                <p><InputText name="keiyakuMeigi" formData={values.keiyakuMeigi} handleValue={handleValue}></InputText></p>
                <FormValidationError formDataList={[values.keiyakuMeigi]}></FormValidationError>
            </FormInputWrapper>
            <FormInputWrapper label="契約者名義カナ" required={true}>
                <p><InputText name="keiyakuMeigiKana" formData={values.keiyakuMeigiKana} handleValue={handleValue}></InputText></p>
                <FormValidationError formDataList={[values.keiyakuMeigiKana]}></FormValidationError>
            </FormInputWrapper>
            <FormInputWrapper label="電話番号" required={true} multi={true}>
                <p><RenderPhoneNumberInputs
                formDataList={[values.denwaBangou1,values.denwaBangou2,values.denwaBangou3]}
                handleValue={handleValue}
                name="denwaBangou"
                >
                </RenderPhoneNumberInputs></p>
                <FormValidationError formDataList={[values.denwaBangou1,values.denwaBangou2,values.denwaBangou3]}></FormValidationError>
            </FormInputWrapper>
            <FormInputWrapper label="お支払い方法" required={true}>
                <CommonSelect name="osiharaiHouhou" options={osiharaiHouhou} formData={values.osiharaiHouhou} handleValue={handleValue} />
            </FormInputWrapper>
        </SectionWithText>
        <Submit label="送信" onClick={submit} error={values.systemError} disabled={isSubmitDisabled}></Submit>
    </Layout>
    </>
    )}
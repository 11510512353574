import React, { useEffect } from "react";
import { CustomSelect } from "../parts/CustomSelect";
import { FORM_A003 } from "../config/form";
import {required,isShortRange,isKana} from "../util/validate";
import {call_api,serchAzaTyouMe,getPostJsonData,addressSearchFromPostNum,serchSiKuTyouSon,updateValues} from "../util/util";
import { displayName } from "../config/config";
import { allTodouHuKen} from "../config/address";
import { Layout } from "../Layout/Layout";
import { Submit } from "../components/Submit";
import { FormInputWrapper } from "../Layout/FormInputWrapper";
import { SectionWithText } from "../Layout/SectionWithText";
import { InputText } from "../parts/InputText";
import { RenderPhoneNumberInputs } from "../components/RenderPhoneNumberInputs";
import { ZipCodeSearch } from "../components/ZipCodeSearch";
import { FormValidationError } from "../components/FormValidationError";
import {TermInputPair} from "../Layout/TermInputPair"
import liff from '@line/liff';

export function A003(props) {
  const loadLabel = "検索中..."
  const [values, setValues] = React.useState(FORM_A003);
  const [postJsonData, setPostJsonData] = React.useState({});
  const [sikuTyouSonJsonData, setSikuTyouSonJsonData] = React.useState({});
  const [azaTyoumeJsonData, setAzaTyoumeJsonData] = React.useState([]);
  const [isSearchClick,setIsSearchClick] = React.useState(false);
  const [isLoading,setIsLoading] = React.useState(false);
  const [fetchInitialDataFinish,setFetchInitialDataFinish] = React.useState(false);
  const [isSubmitDisabled,setIsSubmitDisabled] = React.useState(false);
  const room_id = props.roomId
  let errCount = 0

  useEffect(() => {
    document.title = displayName.A003;
    getPostJsonData(setPostJsonData,setSikuTyouSonJsonData,setAzaTyoumeJsonData)
    fetchInitialData(props.roomId);
  },[]);

  //住所データ三つを監視対象としているが同期処理で返却されるためいずれも同一タイミングで変更検知
  useEffect(() => {
    if(Object.keys(postJsonData).length){
      handleValue("siKuTyouSonOption",serchSiKuTyouSon(values.toDouHuKen.value,sikuTyouSonJsonData))
      handleValue("azaTyoumeOption",serchAzaTyouMe(values.toDouHuKen.value+values.siKuTyouSon.value,azaTyoumeJsonData))

      if(isSearchClick){
        searchClick(values.yubinBangou.value)
        setIsLoading(false)
      }
    } 
  }, [postJsonData,sikuTyouSonJsonData,azaTyoumeJsonData]);

  //初期表示処理
  async function fetchInitialData(_room_id) {
    let form = { "room_id": _room_id }
    let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo", form)
    if (result.status_code !== "200" || !result?.extended_room_tag_info?.shiharai_info){setFetchInitialDataFinish(true);return;} 

    if(result.A00234DefaultViewSetting.is_show){
      let [title, name, address, phoneNum] = result.extended_room_tag_info.shiharai_info.split('\n').map(item => item.split(":")[1]);
      handleValue("siharaiSyaMeigiKana", name);
      const matchResult = address.match(/\((.+)\)/);
      if (matchResult && matchResult.length > 1) {
        let addressInfo = matchResult[1].split(",");
        handleValue("yubinBangou",addressInfo[0].slice(0,7))
        handleValue("toDouHuKen",addressInfo[1])
        handleLabel("toDouHuKen",addressInfo[2])
        handleValue("siKuTyouSon",addressInfo[3])
        handleLabel("siKuTyouSon",addressInfo[4])
        handleValue("azaTyoume",addressInfo[5])
        handleLabel("azaTyoume",addressInfo[6])
        handleValue("bantiGou",addressInfo[7])
        handleValue("tatemonoMei",addressInfo[8])
        handleValue("heyaBangou",addressInfo[9])
      }
      const phoneNumParts = phoneNum.split("-")
      handleValue("siharaiBasyoDenwaBangou1",phoneNumParts[0])
      handleValue("siharaiBasyoDenwaBangou2",phoneNumParts[1])
      handleValue("siharaiBasyoDenwaBangou3",phoneNumParts[2])
    }
    setIsSubmitDisabled(!(result.A00234DefaultViewSetting.is_submit))
    await setFetchInitialDataFinish(true);
  }

  //郵便番号検索
  function searchClick(){
    const yubinBangou = values.yubinBangou.value;
    const isRequiredError = required(yubinBangou);
    const isRangeError = isShortRange(yubinBangou, 7);

    handleError("yubinBangou","requiredError",isRequiredError)
    handleError("yubinBangou","rangeError",isRangeError)
    if(isRequiredError || isRangeError)return;
    if(!(Object.keys(postJsonData).length)) {setIsSearchClick(true);setIsLoading(true);return;}
    //郵便番号で検索
    const address = addressSearchFromPostNum(yubinBangou,postJsonData,azaTyoumeJsonData)
    const notFound = Object.keys(address).length === 0
    handleError("yubinBangou","notFoundAddressError",notFound)
    if(notFound)return;
    tdhChange("toDouHuKen", address.prefecture_name)
    sktsChange("siKuTyouSon",address.municipalitie_name,address.prefecture_name)
    handleValue("azaTyoumeOption",address.addressList)
  }

  //送信処理
  async function submit(){
    //入力チェック
    errCount = 0
    Object.keys(values).forEach(fieldName => {
      const field = values[fieldName];
      if (field.hasOwnProperty("requiredError") && fieldName !== "yubinBangou") {
        handleError(fieldName, "requiredError", required(field.value));
      }
      if (field.hasOwnProperty("rangeError")) {
        handleError(fieldName, "rangeError", isShortRange(field.value, field.maxLength));
      }
      if (field.hasOwnProperty("kanaError") && fieldName !== "yubinBangou") {
        handleError(fieldName, "kanaError", isKana(field.value));
      }
    });
    //郵便番号検索時のみのエラーをリセット
    handleError("yubinBangou","requiredError",false)
    handleError("yubinBangou","rangeError",false)
    handleError("yubinBangou","notFoundAddressError",false)

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    //API連携
    if(errCount === 0){
        //API連携
        let form = {
          "siharaiSyaMeigiKana":values.siharaiSyaMeigiKana.value,
          "yubinBangou":values.yubinBangou.value,
          "toDouHuKenCode":values.toDouHuKen.value,
          "toDouHuKenLabel":values.toDouHuKen.label,
          "siKuTyouSonCode":values.siKuTyouSon.value,
          "siKuTyouSonLabel":values.siKuTyouSon.label,
          "azaTyoumeCode":values.azaTyoume.value,
          "azaTyoumeLabel":values.azaTyoume.label,
          "banTiGou":values.bantiGou.value,
          "tatemonoMei":values.tatemonoMei.value,
          "heyaBangou":values.heyaBangou.value,
          "siharaiSyaDenwaBangou":String(values.siharaiBasyoDenwaBangou1.value) + "-" + String(values.siharaiBasyoDenwaBangou2.value) + "-" + String(values.siharaiBasyoDenwaBangou3.value),
          "display_id":"A003",
          "room_id":room_id
        }
        let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB002",form)
        if(result.status_code === "200"){
            setValues({ ...values, "systemError": false })
            liff.closeWindow()
            window.close()
        }else{
            setValues({ ...values, "systemError": true });
        }
    }
  }

  //以下ハンドラー
  function tdhChange(name,value) {
    let findTdhk = allTodouHuKen.find(item => item.value === value)
    const resetSiKuTyouSonAndAzaTyoume = () => {
      handleValue("siKuTyouSon", "");
      handleLabel("siKuTyouSon", "");
      handleValue("azaTyoume", "");
      handleLabel("azaTyoume", "");
    };
    handleValue(name,value)
    handleLabel(name,value === "" ? value :findTdhk.label)
    resetSiKuTyouSonAndAzaTyoume()
    handleValue("siKuTyouSonOption",serchSiKuTyouSon(value,sikuTyouSonJsonData))
  }
  function sktsChange(name,value,tdhkValue=values.toDouHuKen.value) {
    let findSts = sikuTyouSonJsonData[tdhkValue].find(item => item[1] === value)
    handleValue(name,value)
    handleLabel(name,value === "" ? value :findSts[0])
    handleValue("azaTyoume","")
    handleLabel("azaTyoume","")
    handleValue("azaTyoumeOption",serchAzaTyouMe(tdhkValue+value,azaTyoumeJsonData))
  }
  function atmChange(name,value) {
    let findAtm = azaTyoumeJsonData[values.toDouHuKen.value+values.siKuTyouSon.value].find(item => item[1] === value)
    handleValue(name,value)
    handleLabel(name,value === "" ? value :findAtm[0])
  }
  function handleValue(name, value) {
    updateValues({ [name]: { value } },setValues);
  }
  function handleLabel(name, value) {
    updateValues({ [name]: { label: value } },setValues);
  }
  function handleError(name, error, bool) {
    const updatedValues = { [name]: { [error]: bool }};
    if (bool) errCount += 1;
    updateValues(updatedValues,setValues);
  }
  


  return (
    <>
    {fetchInitialDataFinish && <Layout isLoading={isLoading} label={loadLabel} headerLabel={displayName.A003}>
      <SectionWithText label="電気料金お支払者の情報を入力ください。">
        <FormInputWrapper label="支払者名義カナ" required={true}>
          <p><InputText name="siharaiSyaMeigiKana" formData={values.siharaiSyaMeigiKana} handleValue={handleValue}></InputText></p>
          <FormValidationError formDataList={[values.siharaiSyaMeigiKana]}></FormValidationError>
        </FormInputWrapper>
        <FormInputWrapper label="支払場所住所">
          <TermInputPair label="郵便番号" annotation="※ハイフンなしで検索ください" className="zip">
            <ZipCodeSearch name="yubinBangou" formData={values.yubinBangou} searchClick={searchClick} handleValue={handleValue}></ZipCodeSearch>
          </TermInputPair>
          <FormValidationError formDataList={[values.yubinBangou]} type={"yubinBangou"}></FormValidationError>
          <dl>
            <div className="explanation-box"><p className="explanation-box_txt">検索できない場合は、以下を直接入力してください</p></div>
          </dl>
          <TermInputPair label="都道府県" required={true}>
            <CustomSelect name="toDouHuKen"options={allTodouHuKen} formData={values.toDouHuKen} handleValue={tdhChange} disabled={false}/>
          </TermInputPair>
          <FormValidationError formDataList={[values.toDouHuKen]} type={"addressSelect"}></FormValidationError>
          <TermInputPair label="市区町村" required={true}>
            <CustomSelect name="siKuTyouSon"options={values.siKuTyouSonOption.value} formData={values.siKuTyouSon} handleValue={sktsChange} disabled={values.toDouHuKen.value === ""} />
          </TermInputPair>
          <FormValidationError formDataList={[values.siKuTyouSon]} type={"addressSelect"}></FormValidationError>
          <TermInputPair label="字丁目" required={true}>
            <CustomSelect name="azaTyoume"options={values.azaTyoumeOption.value} formData={values.azaTyoume} handleValue={atmChange} disabled={values.siKuTyouSon.value === ""} />
          </TermInputPair>
          <FormValidationError formDataList={[values.azaTyoume]} type={"addressSelect"}></FormValidationError>
          <TermInputPair label="番地号">
            <InputText name="bantiGou" formData={values.bantiGou} handleValue={handleValue}></InputText>
          </TermInputPair>
          <TermInputPair label="建物名">
            <InputText name="tatemonoMei" formData={values.tatemonoMei} handleValue={handleValue}></InputText>
          </TermInputPair>
          <TermInputPair label="部屋番号">
            <InputText name="heyaBangou" formData={values.heyaBangou} handleValue={handleValue}></InputText>
          </TermInputPair>
          <p className="annotation">マンション・アパートの場合は、建物名、部屋番号を必ず入力してください</p>
        </FormInputWrapper>
        <FormInputWrapper label="支払場所電話番号" required={true} multi={true}>
          <p><RenderPhoneNumberInputs
            formDataList={[values.siharaiBasyoDenwaBangou1,values.siharaiBasyoDenwaBangou2,values.siharaiBasyoDenwaBangou3]}
            handleValue={handleValue}
            name="siharaiBasyoDenwaBangou"
          >
          </RenderPhoneNumberInputs></p>
          <FormValidationError formDataList={[values.siharaiBasyoDenwaBangou1,values.siharaiBasyoDenwaBangou2,values.siharaiBasyoDenwaBangou3]}></FormValidationError>
        </FormInputWrapper>
      </SectionWithText>
      <Submit label="送信" onClick={submit} error={values.systemError} disabled={isSubmitDisabled}></Submit>
    </Layout>}
    </>
  );
}
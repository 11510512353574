import React, { useEffect} from "react";
import { FORM_E001 } from "../config/form";
import {call_api} from "../util/util";
import { errorMessage,displayName } from "../config/config";
import { Link } from "react-router-dom";
import { Layout } from "../Layout/Layout";
import { SystemError } from "../components/SystemError";
import liff from '@line/liff';

export function E001(props) {
  const [values, setValues] = React.useState(FORM_E001);
  const [room_id, setRoomID] = React.useState([]);
  const [cellphonenumber, setCellphonenumber] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [authError, setAuthError] = React.useState(false);
  const [expiredError, setExpiredError] = React.useState(false);
  const [systemError, setSystemError] = React.useState(false);
  const [fetchInitialDataFinish,setFetchInitialDataFinish] = React.useState(false);
  let errCount = 0


  useEffect(() => {
    document.title = displayName.E001;
    setRoomID(props.roomId)
    getRoomInfo(props.roomId)
  },[]);


  //type=tel
  function handleInputChangeNum(e) {
    const re = /^[0-9\b]+$/;
    const target = e.target;
    const value = target.value;
    const name = target.name;
    //正規表現に一致したもののみ設定
    if (value === '' || re.test(value)) {
        handleValue(name,value)
        if(value.length === 4){
          submit()
        } 
    }else{
        setValues({ ...values, [name]: values[name] });
    }
  }

  function handleValue(name,value) {
    let copy = values[name]
    copy["value"] = value
    setValues({ ...values, [name]: copy });    
    console.log(values)
  }


  async function sendAuthCode(event){
    event.preventDefault();
    await setLoading(true)

    let form = {
      "room_id":room_id,
    }
    let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB005",form)
    if(result.status_code === "200"){
      setSystemError(false)
      alert("再送しました")
    }else{
      setSystemError(true)
    }

    await setLoading(false)
    await setAuthError(false);
    await setExpiredError(false)
  }

  async function getRoomInfo(_room_id){
    let form = {
      "room_id":_room_id,
    }
    let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo",form)
    await setCellphonenumber(result?.extended_room_tag_info?.sms_phone_no)
    await setFetchInitialDataFinish(true);
  }

  async function submit(){
    //入力チェック
    errCount = 0
    window.scrollTo({top: 0,behavior: 'smooth',})
    //API連携
    if(errCount === 0){
      await setLoading(true)
      let form = {
        "room_id":room_id,
        "auth_code":values.authCode.value,
      }
      let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB003",form)
      if(result.status_code === "200"){
        if(result.error.count > 0 && result.error.content.type === "auth_fail"){
          setAuthError(true);
        }else if(result.error.count > 0 && result.error.content.type === "auth_expired"){
          setExpiredError(true)
        }else{
          liff.closeWindow()
          window.close()
        }
      }else{
        setSystemError(true)
      }
    }
    await handleValue("authCode","")
    await setLoading(false)
  }

  
  return (
    <>
    {fetchInitialDataFinish && <Layout isLoading={loading} label="送信中" headerLabel={displayName.E001}>
      <p className="lead">+81{cellphonenumber}<br />上記番号に送信された認証コードを<br />入力してください</p>
      <div id="form">
        <form action="">
          <section className="area">
            <section>
                <section className="">
                  <div className="certification-box">
                    <div className="certification-box_txt required">認証コード（4桁）</div>
                    <p>
                      <input type="text" inputMode="numeric" name="authCode" onChange={handleInputChangeNum} value={values.authCode.value} maxLength="4" autoComplete="one-time-code" />
                    </p>
                  </div>
                  <div className="txtlink-box">
                    {authError && <p className="validation-error-txt">{errorMessage.authFail}</p>}
                    {expiredError && <p className="validation-error-txt">{errorMessage.authExpired}</p>}
                    <p><a href="" onClick={sendAuthCode}>認証コードを再送信する</a></p>
                    <p><Link to="/keitaiBangouTeisei">認証コードの送信先携帯電話番号を変更する</Link></p>
                  </div>
                </section>
            </section>       
            {systemError && <SystemError />}
          </section>
        </form>
      </div>
    </Layout>}
    </>
  );
}
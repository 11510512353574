import React, { useEffect } from "react";
import { FORM_A002 } from "../config/form";
import {required,isShortRange} from "../util/validate";
import {call_api,updateValues} from "../util/util";
import {displayName,B002_radio } from "../config/config";
import { Layout } from "../Layout/Layout";
import { Submit } from "../components/Submit";
import { SectionWithText } from "../Layout/SectionWithText";
import { InputTel } from "../parts/InputTel";
import { InputRadio } from "../parts/InputRadio";
import { FormValidationError } from "../components/FormValidationError";
import liff from '@line/liff';

export function A002(props) {
  document.title = displayName.A002;
  const [values, setValues] = React.useState(FORM_A002);
  const [paymFirst, ...paymRest] = B002_radio
  const [fetchInitialDataFinish,setFetchInitialDataFinish] = React.useState(false);
  const [isSubmitDisabled,setIsSubmitDisabled] = React.useState(false);
  
  const room_id = props.roomId
  let errCount = 0

  useEffect(() => {
    fetchInitialData(room_id);
  },[]);

  //初期表示処理
  async function fetchInitialData(_room_id) {
    let form = { "room_id": _room_id }
    let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo", form)
    if (result.status_code !== "200" || !result?.extended_room_tag_info?.shiharai_houhou){setFetchInitialDataFinish(true);return;} 

    if(result.A00234DefaultViewSetting.is_show){
      let [title, payment, acNum] = result.extended_room_tag_info.shiharai_houhou.split('\n').map(item => item.split(":")[1]);
      handleValue("siharaiHouhou", payment);
      handleValue("kouzaBangou", acNum ?? "");
    }
    setIsSubmitDisabled(!(result.A00234DefaultViewSetting.is_submit))
    await setFetchInitialDataFinish(true);
  }

  //送信処理
  async function submit(){
    //入力チェック
    errCount = 0
    handleError("kouzaBangou","requiredError",values.siharaiHouhou.value==="口座振替" && required(values.kouzaBangou.value))
    handleError("kouzaBangou","rangeError",isShortRange(values.kouzaBangou.value,values.kouzaBangou.maxLength))
    window.scrollTo({top: 0,behavior: 'smooth',})
    //API連携
    if(errCount === 0){
      let form = {
        "siharaiHouhou":values.siharaiHouhou.value,
        "kouzaBangou":values.kouzaBangou.value,
        "display_id":"A002",
        "room_id":room_id
      }
      let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB002",form)
      if(result.status_code === "200"){
        setValues({ ...values, "systemError": false })
        liff.closeWindow()
        window.close()
      }else{
        setValues({ ...values, "systemError": true });
      }
    }
  }

  //以下ハンドラー
  function handleRadioChange(name,value) {
    handleValue(name,value)
    if(value !== "口座振替"){
      handleValue("kouzaBangou","")
    }
  }
  function handleValue(name, value) {
    updateValues({ [name]: { value } },setValues);
  }
  function handleError(name, error, bool) {
    const updatedValues = { [name]: { [error]: bool } };
    if (bool) errCount += 1;
    updateValues(updatedValues,setValues);
  }

  return (
    <>
    {fetchInitialDataFinish && <Layout isLoading={false} headerLabel={displayName.A002}>
      <SectionWithText label={"現在の電気料金の支払い方法を選択し必要事項を入力してください。"} h2Class="required">
        <div className="formSet">
          <div className="radio">
            <InputRadio name="siharaiHouhou" radioInfo={[paymFirst]} formData={values.siharaiHouhou} handleValue={handleRadioChange}></InputRadio>
            <dl className="radio_ml">
              <dt className="">口座番号下4桁</dt>
              <dd className="w40">
                <p><InputTel name="kouzaBangou" formData={values.kouzaBangou} handleValue={handleValue} disabled={values.siharaiHouhou.value !== "口座振替"}></InputTel></p>
              </dd>
              <FormValidationError formDataList={[values.kouzaBangou]}></FormValidationError>
            </dl>
            <InputRadio name="siharaiHouhou" radioInfo={paymRest} formData={values.siharaiHouhou} handleValue={handleRadioChange}></InputRadio>
          </div>
        </div>
      </SectionWithText>
      <Submit label="送信" onClick={submit} error={values.systemError} disabled={isSubmitDisabled}></Submit>
      
    </Layout>}
    </>
  );
}
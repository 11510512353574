import React, { useEffect } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import {call_api} from "../util/util";
import { FORM_K001 } from "../config/form";
import { displayName } from "../config/config";
import liff from '@line/liff';

export function K001(props) {
  const isCsNumAnswer = props.answer === "csNum"
  const [values, setValues] = React.useState(FORM_K001);
  const [loading, setLoading] = React.useState(true);
  const [textareaHeight,settextareaHeight] =  React.useState("auto");

  useEffect(() => {
    document.title = displayName.K001;
    getRoomInfo(props.roomId)
    setValues({ ...values, "systemError": true })
  },[]);

  //textAreaの高さ設定
  useEffect(() => {
    settextareaHeight(document.getElementById("freeKaitou").scrollHeight)
  },[values.freeKaitou.value]);

  async function getRoomInfo(_room_id){
    let form = {
      "room_id":_room_id,
    }
    let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo",form)
    await setLoading(false)
    if(result.status_code !== "200" || result.error !== ""){
      setValues({ ...values, "systemError": true })
    }else{
      if(isCsNumAnswer){
        let answer = `お客さま番号：${result.extended_room_tag_info.cs_no1}\n供給地点特定番号：${result.extended_room_tag_info.kyokyuti_no}`
        handleValue("freeKaitou",answer)
      }else{
        handleValue("freeKaitou",result.extended_room_tag_info.free_kaitou)
      }
      setValues({ ...values, "systemError": false })
    }
  }

  function closeBtn(){
    liff.closeWindow()
    window.close()
  }

  function handleValue(name,value) {
    let copy = values[name]
    copy["value"] = value
    setValues({ ...values, [name]: copy });
  }


  return (
    <div style={loading?{display:"none"}:{display:"block"}}>
      <Header label=""/>
      <div id="form">
        <form action="">
          <section className="area">
            <section>
              <div className="formSet">
                <textarea id="freeKaitou" disabled style={{ height: textareaHeight+"px",color: '#000000',opacity: 1 }} value={values.freeKaitou.value} />
              </div>
            </section>            
          </section>
          <section className="submit">
            <ul>
              <li>
                <button type="button" onClick={closeBtn} className="activeBtn"><span className="btn_inner">閉じる</span></button>
              </li>
            </ul>
            {values.systemError && 
              <div className="validation-error-box">現在システムエラーが発生しております。画面を閉じて、オペレーターにエラー内容をお伝えください。</div>
            }
            </section>
        </form>
      </div>
      <Footer/>
    </div>
  );
}
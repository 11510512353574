import React from "react";
import { SystemError } from "../components/SystemError";

export function Submit({label,onClick,error,disabled=false}) {
  let cssClass = disabled ? "disabledBtn" :"activeBtn";
  return (
      <section className="submit">
          <ul>
            <li>
              <button type="button" onClick={onClick} className={cssClass} disabled={disabled}><span className="btn_inner">{label}</span></button>
            </li>
          </ul>
          {error && <SystemError />}
        </section>
  );
}
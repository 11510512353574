import React from "react";

export function CustomerNumber({name,customNumbers=[],handleValue}) {
    function handleInputChange(e) {
        const re = /^[0-9\b]+$/;
        const maxLength = e.target.maxLength
        if (e.target.value === '' || re.test(e.target.value)) {
          handleValue(e.target.name,e.target.value)
          if(e.target.value.length === maxLength){
            let num = Number(e.target.name.slice(-1)) + 1
            let nextName = e.target.name.slice(0,e.target.name.length-1) + String(num)
            document.getElementsByName(nextName)[0].focus()
          }
        }
    }
    return (
      <>
                {customNumbers.map((customNumber,index) => (
                  <React.Fragment key={name+"_"+String(index)}>
                  <input
                    type="tel"
                    name={name+"_"+String(index+1)}
                    onChange={handleInputChange}
                    value={customNumber.value}
                    maxLength={customNumber.maxLength}
                    className={`${customNumber.style} ${customNumber.rangeError || customNumber.requiredError ||  customNumber.correlationRequiredError ? 'is-invalid' :''}`} 
                    disabled={index===5}
                  />
                  {index !== 5 &&<span>-</span>}
                  </React.Fragment>
                ))}
      </>

    );
}
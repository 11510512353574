import {getTodayFormat_yyyy_mm_dd,getTodayFormat_yyyy_mm,getYearAgoFormat_yyyy_mm} from "../util/util";

export const FORM_A001 = {
  genzaiOtukainoDenwaBangou1:{
    value:"",
    maxLength:3,
    requiredError:false,
    rangeError:false,
    phoneFormatError:false
  },
  genzaiOtukainoDenwaBangou2:{
    value:"",
    maxLength:4,
    requiredError:false,
    rangeError:false,
  },
  genzaiOtukainoDenwaBangou3:{
    value:"",
    maxLength:4,
    requiredError:false,
    rangeError:false,
  },
  mousikomiSyaKbn:{
    value:"",
    correlationRequiredError:false,
  },
  keiyakuSyaTonoGokankei:{
    value:"",
    maxLength:17,
    correlationRequiredError:false,
    hankakuError:false
  },
  mousikomiSyaMeigi:{
    value:"",
    maxLength:17,
    correlationRequiredError:false,
    hankakuError:false
  },
  keiyakuSyaMeigiKana:{
    value:"",
    maxLength:17,
    requiredError:false,
    kanaError:false
  },
  yubinBangou:{
    value:"",
    maxLength:7,
    requiredError:false,
    rangeError:false,
    notKyusyuError:false,
    notFoundAddressError:false,
    rangeErrorSubmit:false,
  },
  toDouHuKen:{
    value:"",
    label:"",
    requiredError:false
  },
  siKuTyouSon:{
    value:"",
    label:"",
    requiredError:false
  },
  azaTyoume:{
    value:"",
    label:"",
    requiredError:false
  },
  bantiGou:{
    value:"",
    maxLength:17
  },
  tatemonoMei:{
    value:"",
    maxLength:17
  },
  heyaBangou:{
    value:"",
    maxLength:17
  },
  okyakusamaBangou1_1:{
    value:"",
    maxLength:2,
    correlationRequiredError:false,
    rangeError:false,
    A001_CorrelationRequiredError:false,
    style:"fg02"
  },
  okyakusamaBangou1_2:{
    value:"",
    maxLength:3,
    correlationRequiredError:false,
    rangeError:false,
    style:"fg03"
  },
  okyakusamaBangou1_3:{
    value:"",
    maxLength:3,
    correlationRequiredError:false,
    rangeError:false,
    style:"fg03"
  },
  okyakusamaBangou1_4:{
    value:"",
    maxLength:2,
    correlationRequiredError:false,
    rangeError:false,
    style:"fg02"
  },
  okyakusamaBangou1_5:{
    value:"",
    maxLength:7,
    correlationRequiredError:false,
    rangeError:false,
    style:"fg07"
  },
  okyakusamaBangou1_6:{
    value:"31",
    maxLength:2,
    style:"fg02"
  },
  okyakusamaBangou2_1:{
    value:"",
    maxLength:2,
    correlationRequiredError:false,
    rangeError:false,
    style:"fg02"
  },
  okyakusamaBangou2_2:{
    value:"",
    maxLength:3,
    correlationRequiredError:false,
    rangeError:false,
    style:"fg03"
  },
  okyakusamaBangou2_3:{
    value:"",
    maxLength:3,
    correlationRequiredError:false,
    rangeError:false,
    style:"fg03"
  },
  okyakusamaBangou2_4:{
    value:"",
    maxLength:2,
    correlationRequiredError:false,
    rangeError:false,
    style:"fg02"
  },
  okyakusamaBangou2_5:{
    value:"",
    maxLength:7,
    correlationRequiredError:false,
    rangeError:false,
    style:"fg07"
  },
  okyakusamaBangou2_6:{
    value:"31",
    maxLength:2,
    style:"fg02"
  },
  denwaBangouRadio:{
    value:"2"
  },
  gotourokuNoDenwaBangou1:{
    value:"",
    maxLength:6,
    requiredError:false
  },
  gotourokuNoDenwaBangou2:{
    value:"",
    maxLength:4,
    requiredError:false
  },
  gotourokuNoDenwaBangou3:{
    value:"",
    maxLength:4,
    requiredError:false
  },
  siKuTyouSonOption:{
    value:[{value:"",label:"検索中..."}]
  },
  azaTyoumeOption:{
    value:[{value:"",label:"検索中..."}]
  },
}

export const FORM_A002 = {
  siharaiHouhou:{
    value:"口座振替",
  },
  kouzaBangou:{
    value:"",
    maxLength:4,
    correlationRequiredError:false,
    rangeError:false
  },
  systemError:false
  }

export const FORM_A003 = {
  siharaiSyaMeigiKana:{
  value:"",
  requiredError:false,
  kanaError:false
},
yubinBangou:{
  value:"",
  maxLength:7,
  requiredError:false,
  rangeError:false,
  notFoundAddressError:false,
  rangeErrorSubmit:false,
},
toDouHuKen:{
  value:"",
  label:"",
  requiredError:false
},
siKuTyouSon:{
  value:"",
  label:"",
  requiredError:false
},
azaTyoume:{
  value:"",
  label:"",
  requiredError:false
},
bantiGou:{
  value:"",
  maxLength:17
},
tatemonoMei:{
  value:"",
  maxLength:17
},
heyaBangou:{
  value:"",
  maxLength:17
},
siharaiBasyoDenwaBangou1:{
  value:"",
  maxLength:6,
  requiredError:false,
},
siharaiBasyoDenwaBangou2:{
  value:"",
  maxLength:4,
  requiredError:false,
},
siharaiBasyoDenwaBangou3:{
  value:"",
  maxLength:4,
  requiredError:false,
},

siKuTyouSonOption:{
  value:[{value:"",label:"検索中..."}]
},
azaTyoumeOption:{
  value:[{value:"",label:"検索中..."}]
},
systemError:false
}

export const FORM_A004 = {
  keikiBangou:{
    value:"",
    maxLength:7,
    rangeError:false,
  },
  dentyuBangou1:{
    value:"",
    maxLength:3,
    requiredError:false,
    rangeError:false,
  },
  dentyuBangou2:{
    value:"",
    maxLength:1,
    requiredError:false,
    kanaError:false
  },
  dentyuBangou3:{
    value:"",
    maxLength:3,
    requiredError:false,
    rangeError:false,
  }
}

export const FORM_B001 = {
  taisyouTuki1:{
  value:"",
  min:getYearAgoFormat_yyyy_mm(),
  max:getTodayFormat_yyyy_mm(),
  requiredError:false,
},
taisyouTuki2:{
  value:"",
  min:getYearAgoFormat_yyyy_mm(),
  max:getTodayFormat_yyyy_mm(),
},
systemError:false
}

export const FORM_B002 = {
  taisyouTuki1:{
  value:"",
  min:getYearAgoFormat_yyyy_mm(),
  max:getTodayFormat_yyyy_mm(),
  requiredError:false,
},
taisyouTuki2:{
  value:"",
  min:getYearAgoFormat_yyyy_mm(),
  max:getTodayFormat_yyyy_mm(),
},
systemError:false
}

export const FORM_C001 = {
  mousikomiSyaMeiKana:{
    value:"",
    requiredError:false,
    kanaError:false
  },
  mousikomiSyaDenwaBangou1:{
    value:"",
    requiredError:false,
  },
  mousikomiSyaDenwaBangou2:{
    value:"",
    requiredError:false,
  },
  mousikomiSyaDenwaBangou3:{
    value:"",
    requiredError:false,
  },
  mousikomiSyaDenwaBangouKubun:{
    value:"",
    requiredError:false
  },
  henkouYoteibi:{
    value:getTodayFormat_yyyy_mm_dd(),
    requiredError:false,
    min:getTodayFormat_yyyy_mm_dd()
  },
  sinKeiyakuMeigi:{
    value:"",
    maxLength:17,
    requiredError:false,
    hankakuError:false
  },
  sinKeiyakuMeigiKana:{
    value:"",
    maxLength:17,
    requiredError:false,
    kanaError:false
  },
  henkougoDenwaBangou1:{
    value:"",
    maxLength:6,
    requiredError:false,
  },
  henkougoDenwaBangou2:{
    value:"",
    maxLength:4,
    requiredError:false,
  },
  henkougoDenwaBangou3:{
    value:"",
    maxLength:4,
    requiredError:false,
  },
  yubinBangou:{
    value:"",
    maxLength:7,
    requiredError:false,
    rangeError:false,
    notKyusyuError:false,
    notFoundAddressError:false,
    rangeErrorSubmit:false,
  },
  toDouHuKen:{
    value:"",
    label:"",
    requiredError:false
  },
  siKuTyouSon:{
    value:"",
    label:"",
    requiredError:false
  },
  azaTyoume:{
    value:"",
    label:"",
    requiredError:false
  },
  bantiGou:{
    value:"",
    maxLength:17
  },
  tatemonoMei:{
    value:"",
    maxLength:17
  },
  heyaBangou:{
    value:"",
    maxLength:17
  },
  syomenHakkounoYouhi:{
    value:"",
    requiredError:false
  },
  taisyouTuki1:{
    value:"",
    min:getYearAgoFormat_yyyy_mm(),
    max:getTodayFormat_yyyy_mm(),
    requiredError:false,
  },
  taisyouTuki2:{
    value:"",
    min:getYearAgoFormat_yyyy_mm(),
    max:getTodayFormat_yyyy_mm(),
  },
  atena:{
    value:"",
    maxLength:17,
    hankakuError:false,
  },
  saiHakkouRiyu:{
    value:"",
    requiredError:false,
  },
  gosiyouKaisiBi:{
    value:"",
    requiredError:false,
    min:"",
  },
  siKuTyouSonOption:{
    value:[{value:"",label:"検索中..."}]
  },
  azaTyoumeOption:{
    value:[{value:"",label:"検索中..."}]
  },
  systemError:false
}

export const FORM_C007 = {
  gosiyouKaisiBi:{
    value:"",
    requiredError:false,
    min:getTodayFormat_yyyy_mm_dd(),
  },
  yubinBangou:{
    value:"",
    maxLength:7,
    requiredError:false,
    rangeError:false,
    notKyusyuError:false,
    notFoundAddressError:false,
    rangeErrorSubmit:false,
  },
  toDouHuKen:{
    value:"",
    label:"",
    requiredError:false
  },
  siKuTyouSon:{
    value:"",
    label:"",
    requiredError:false
  },
  azaTyoume:{
    value:"",
    label:"",
    requiredError:false
  },
  bantiGou:{
    value:"",
    maxLength:17
  },
  tatemonoMei:{
    value:"",
    maxLength:17
  },
  heyaBangou:{
    value:"",
    maxLength:17
  },
  mousikomiSyaKbn:{
    value:"",
    requiredError:false
  },
  keiyakuSyaTonoGokankei:{
    value:"",
    maxLength:17,
    correlationRequiredError:false,
    hankakuError:false
  },
  mousikomiSyaMeigi:{
    value:"",
    maxLength:17,
    hankakuError:false,
    requiredError:false
  },
  keiyakuMeigi:{
    value:"",
    maxLength:17,
    requiredError:false,
    hankakuError:false
  },
  keiyakuMeigiKana:{
    value:"",
    maxLength:17,
    requiredError:false,
    kanaError:false
  },
  denwaBangou1:{
    value:"",
    maxLength:6,
    requiredError:false,
  },
  denwaBangou2:{
    value:"",
    maxLength:4,
    requiredError:false,
  },
  denwaBangou3:{
    value:"",
    maxLength:4,
    requiredError:false,
  },
  osiharaiHouhou:{
    value:"",
    requiredError:false,
  },
  siKuTyouSonOption:{
    value:[{value:"",label:"検索中..."}]
  },
  azaTyoumeOption:{
    value:[{value:"",label:"検索中..."}]
  },
  systemError:false
}

export const FORM_D001 = {
  freeNyuryoku:{
    value:"",
    requiredError:false,
  },
  systemError:false
}

export const FORM_E001 = {
  authCode:{
    value:"",
    requiredError:false,
  },
  authFail:false,
  authExpired:false,
  systemError:false,
}

export const FORM_G001 = {
  phoneNumber1:{
    value:"",
    requiredError:false,
    rangeError:false,
    phoneFormatError:false
  },
  phoneNumber2:{
    value:"",
    requiredError:false,
    rangeError:false,
  },
  phoneNumber3:{
    value:"",
    requiredError:false,
    rangeError:false,
  },
  systemError:false,
}

export const FORM_K001 = {
  freeKaitou:{
    value:"",
  },
  systemError:false,
}
import React from 'react';

export function SectionWithText({ children,label="",h2Class=""}){
  const lines = label.split('\n');
  return (
    <section>
      <h2 className={h2Class}>
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index !== label.split('\n').length - 1 && <br className="sp" />}
          </React.Fragment>
        ))}
      </h2>
      {children}
    </section>
  );
};

export default SectionWithText;
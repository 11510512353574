import React, { useEffect } from "react";
import { CustomSelect } from "../parts/CustomSelect";
import { CommonSelect } from "../parts/CommonSelect";
import { FORM_A001 } from "../config/form";
import {required,isShortRange,isKana, isPhoneFormat,correlationRequired,isZenkaku} from "../util/validate";
import {call_api,serchAzaTyouMe,getPostJsonData,addressSearchFromPostNum,serchSiKuTyouSon,updateValues} from "../util/util";
import { displayName,A001_mousikomiSyaKubun,phoneNumManualInputSelected} from "../config/config";
import { kyusyuTodouHuKen} from "../config/address";
import { Layout } from "../Layout/Layout";
import { FormInputWrapper } from "../Layout/FormInputWrapper";
import { SectionWithText } from "../Layout/SectionWithText";
import { InputText } from "../parts/InputText";
import { InputRadio } from "../parts/InputRadio";
import { FormValidationError } from "../components/FormValidationError";
import {TermInputPair} from "../Layout/TermInputPair"
import { RenderPhoneNumberInputs } from "../components/RenderPhoneNumberInputs";
import { ZipCodeSearch } from "../components/ZipCodeSearch";
import { CustomerNumber } from "../components/CustomerNumber";
import { Submit } from "../components/Submit";
import { kyusyuKenList,allTodouHuKen} from "../config/address";
import liff from '@line/liff';

export function A001(props) {
  const room_id = props.roomId
  const [values, setValues] = React.useState(FORM_A001);
  const [postJsonData, setPostJsonData] = React.useState({});
  const [sikuTyouSonJsonData, setSikuTyouSonJsonData] = React.useState({});
  const [azaTyoumeJsonData, setAzaTyoumeJsonData] = React.useState({});
  const [customerNumberVisible,setCustomerNumberVisible] = React.useState(false);
  const [isLoading,setIsLoading] = React.useState(false);
  const [loadLabel,setLoadLabel] = React.useState("検索中...");
  const [isSelf,setIsSelf] = React.useState(false);
  const [fetchInitialDataFinish,setFetchInitialDataFinish] = React.useState(false);
  const customerInquiryQuestions = ["ご使用停止","停止および開始（移転先）","登録の電話番号変更","郵送物の送付先住所変更","電気供給設備の撤去","電気料金・使用量の確認","料金プランの確認","契約容量の確認"]
  const [toiawaseNaiyou,setToiawaseNaiyou]= React.useState("");
  const [isSearchClick,setIsSearchClick] = React.useState(false);
  let errCount = 0

  useEffect(() => {
    document.title = displayName.A001;
    getPostJsonData(setPostJsonData,setSikuTyouSonJsonData,setAzaTyoumeJsonData)
    fetchInitialData(props.roomId);
  },[]);

  useEffect(() => {
    const denwaBangouRadioValue = values.denwaBangouRadio.value;
    const clearValues = denwaBangouRadioValue === "2"
      ? ["", "", ""]
      : [
          values["genzaiOtukainoDenwaBangou1"].value,
          values["genzaiOtukainoDenwaBangou2"].value,
          values["genzaiOtukainoDenwaBangou3"].value
        ];
    ["gotourokuNoDenwaBangou1", "gotourokuNoDenwaBangou2", "gotourokuNoDenwaBangou3"].forEach((key, index) => {
      handleValue(key, clearValues[index]);
    });
  }, [values.denwaBangouRadio.value]);

  useEffect(() => {
    const denwaBangouRadioValue = values.denwaBangouRadio.value;
    if(denwaBangouRadioValue === "1"){
      let saveValues = [values["genzaiOtukainoDenwaBangou1"].value,values["genzaiOtukainoDenwaBangou2"].value,values["genzaiOtukainoDenwaBangou3"].value];
      ["gotourokuNoDenwaBangou1", "gotourokuNoDenwaBangou2", "gotourokuNoDenwaBangou3"].forEach((key, index) => {
        handleValue(key, saveValues[index]);
      });
    }
  }, [values.genzaiOtukainoDenwaBangou1.value,
      values.genzaiOtukainoDenwaBangou2.value,
      values.genzaiOtukainoDenwaBangou3.value
  ]);

  //住所データ三つを監視対象としているが同期処理で返却されるためいずれも同一タイミングで変更検知
  useEffect(() => {
    if(Object.keys(postJsonData).length){
      handleValue("siKuTyouSonOption",serchSiKuTyouSon(values.toDouHuKen.value,sikuTyouSonJsonData))
      handleValue("azaTyoumeOption",serchAzaTyouMe(values.toDouHuKen.value+values.siKuTyouSon.value,azaTyoumeJsonData))

      if(isSearchClick){
        searchClick(values.yubinBangou.value)
        setIsLoading(false)
      }
    } 
  }, [postJsonData,sikuTyouSonJsonData,azaTyoumeJsonData]);

  //郵便番号検索処理
  function searchClick(){
    const yubinBangou = values.yubinBangou.value;
    const isRequiredError = required(yubinBangou);
    const isRangeError = isShortRange(yubinBangou, 7);

    handleError("yubinBangou","requiredError",isRequiredError)
    handleError("yubinBangou","rangeError",isRangeError)

    if(isRequiredError || isRangeError)return;
    if(!(Object.keys(postJsonData).length)) {setIsSearchClick(true);setIsLoading(true);return;}

    //郵便番号で検索
    const address = addressSearchFromPostNum(yubinBangou,postJsonData,azaTyoumeJsonData)

    const notFound = Object.keys(address).length === 0
    const isKyusyu = kyusyuKenList.includes(address.prefecture_name)
    handleError("yubinBangou","notFoundAddressError",notFound)
    handleError("yubinBangou","notKyusyuError",!notFound && !isKyusyu)
    if(!isKyusyu || notFound)return;

    tdhChange("toDouHuKen", address.prefecture_name)
    sktsChange("siKuTyouSon",address.municipalitie_name,address.prefecture_name)
    handleValue("azaTyoumeOption",address.addressList)
  }

  //初期表示処理
  async function fetchInitialData(_room_id){
    let form = {"room_id":_room_id,}
    let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo",form)
    if(result.status_code==="200"){
      let _isSelf = result?.extended_room_tag_info?.mks_info === "本人"
      await setCustomerNumberVisible(shouldSetCustomerNumberVisible(result))
      await setIsSelf(_isSelf)
      //選択したラジオボタンの保存領域はないため実値比較し、設定
      if(_isSelf){
        await handleValue("denwaBangouRadio",result?.extended_room_tag_info?.sms_phone_no === result?.extended_room_tag_info?.gtk_phone_no ? "1" :"2")
      }

      await setToiawaseNaiyou(result?.extended_room_tag_info?.toiawase_naiyou)
      await initialViewState(result?.defaultViewSetting,result?.roomInfo,result?.extended_room_tag_info)
    }
    await setFetchInitialDataFinish(true)
    return result
  }

  //初期データ反映
  async function initialViewState(defaultViewSetting,roomInfo,extendedRoomTag){
    //入力内容復元
    if(defaultViewSetting?.is_show){
      const tag43Parts = extendedRoomTag.sms_phone_no.split("-")
      handleValue("genzaiOtukainoDenwaBangou1",tag43Parts[0])
      handleValue("genzaiOtukainoDenwaBangou2",tag43Parts[1])
      handleValue("genzaiOtukainoDenwaBangou3",tag43Parts[2])
      handleValue("keiyakuSyaMeigiKana",extendedRoomTag.keiyaku_meigi)
      const matchResult = extendedRoomTag.address.match(/\((.+)\)/);
      if (matchResult && matchResult.length > 1) {
        let addressInfo = matchResult[1].split(",");
        handleValue("yubinBangou",addressInfo[0].slice(0,7))
        handleValue("toDouHuKen",addressInfo[1])
        handleLabel("toDouHuKen",addressInfo[2])
        handleValue("siKuTyouSon",addressInfo[3])
        handleLabel("siKuTyouSon",addressInfo[4])
        handleValue("azaTyoume",addressInfo[5])
        handleLabel("azaTyoume",addressInfo[6])
        handleValue("bantiGou",addressInfo[7])
        handleValue("tatemonoMei",addressInfo[8])
        handleValue("heyaBangou",addressInfo[9])
      }
      let gtkDenwaBangouParts = extendedRoomTag.gtk_phone_no.split("-")
      handleValue("gotourokuNoDenwaBangou1",gtkDenwaBangouParts[0] ??"")
      handleValue("gotourokuNoDenwaBangou2",gtkDenwaBangouParts[1] ??"")
      handleValue("gotourokuNoDenwaBangou3",gtkDenwaBangouParts[2] ??"")

      //申込者区分が本人以外の場合のみ復元
      if(!isSelf){
        handleValue("mousikomiSyaKbn",extendedRoomTag.mks_kbn_code)
        handleValue("keiyakuSyaTonoGokankei",extendedRoomTag.kys_kankei)
        handleValue("mousikomiSyaMeigi",extendedRoomTag.mks_name)
      }
      //お客さま番号エリアが活性の場合のみ復元処理を実行
      if(!customerNumberVisible){
        let okyakusamaBangou1Parts = extendedRoomTag.cs_no1.split("-")
        handleValue("okyakusamaBangou1_1",okyakusamaBangou1Parts[0] ??"")
        handleValue("okyakusamaBangou1_2",okyakusamaBangou1Parts[1] ??"")
        handleValue("okyakusamaBangou1_3",okyakusamaBangou1Parts[2] ??"")
        handleValue("okyakusamaBangou1_4",okyakusamaBangou1Parts[3] ??"")
        handleValue("okyakusamaBangou1_5",okyakusamaBangou1Parts[4] ??"")
        let okyakusamaBangou2Parts = extendedRoomTag.cs_no2.split("-")
        handleValue("okyakusamaBangou2_1",okyakusamaBangou2Parts[0] ??"")
        handleValue("okyakusamaBangou2_2",okyakusamaBangou2Parts[1] ??"")
        handleValue("okyakusamaBangou2_3",okyakusamaBangou2Parts[2] ??"")
        handleValue("okyakusamaBangou2_4",okyakusamaBangou2Parts[3] ??"")
        handleValue("okyakusamaBangou2_5",okyakusamaBangou2Parts[4] ??"")
      }
    }
    //編集不可
    if(!(defaultViewSetting?.is_edit)){
      alert("すでに送信済のため、再送信はできません。")
      setLoadLabel("")
      await setIsLoading(true)
    }
  }

  //お客さま番号エリアの出力判定
  const shouldSetCustomerNumberVisible = (result) => {
    const { toiawase_naiyou, osiharai_houhou } = result?.extended_room_tag_info || {};
    if (toiawase_naiyou === "お支払い方法") {
      return osiharai_houhou === "振込票払い" ? false:true;
    }
    return customerInquiryQuestions.includes(toiawase_naiyou);
  };

  //送信処理
  async function submit(){
    let okyakusamaBangou1 = getValue("okyakusamaBangou1_1") + getValue("okyakusamaBangou1_2") + getValue("okyakusamaBangou1_3") + getValue("okyakusamaBangou1_4") + getValue("okyakusamaBangou1_5")
    let okyakusamaBangou2 = getValue("okyakusamaBangou2_1") + getValue("okyakusamaBangou2_2") + getValue("okyakusamaBangou2_3") + getValue("okyakusamaBangou2_4") + getValue("okyakusamaBangou2_5")
    const handleCorrelationError = (prefix, count, correlationValues) => {
      for (let i = 1; i <= count; i++) {
        const fieldName = `${prefix}${i}`;
        handleError(fieldName, "correlationRequiredError", correlationRequired(values[fieldName].value, correlationValues));
      }
    };

    //入力チェック
    errCount = 0
    Object.keys(values).forEach(fieldName => {
      const field = values[fieldName];
      if (field.hasOwnProperty("requiredError") && fieldName !== "yubinBangou") {
        handleError(fieldName, "requiredError", required(field.value));
      }
      if (field.hasOwnProperty("rangeError")) {
        handleError(fieldName, "rangeError", isShortRange(field.value, field.maxLength));
      }
      if (field.hasOwnProperty("phoneFormatError") && fieldName !== "yubinBangou") {
        handleError(fieldName, "phoneFormatError", isPhoneFormat(field.value));
      }
      if (field.hasOwnProperty("kanaError") && fieldName !== "yubinBangou") {
        handleError(fieldName, "kanaError", isKana(field.value));
      }
      if (field.hasOwnProperty("hankakuError")) {
        handleError(fieldName, "hankakuError", isZenkaku(field.value));
      }
    });
    // 相関チェック
    handleError("mousikomiSyaKbn","correlationRequiredError",!isSelf && required(values.mousikomiSyaKbn.value))
    handleError("keiyakuSyaTonoGokankei","correlationRequiredError",["02", "99"].includes(values.mousikomiSyaKbn.value) && required(values.keiyakuSyaTonoGokankei.value))
    handleError("mousikomiSyaMeigi","correlationRequiredError",!isSelf && required(values.mousikomiSyaMeigi.value))
    handleCorrelationError("okyakusamaBangou1_", 5,okyakusamaBangou1)
    handleCorrelationError("okyakusamaBangou2_", 5,okyakusamaBangou2)
    handleError("okyakusamaBangou1_1","A001_CorrelationRequiredError",Boolean((!(okyakusamaBangou1) && okyakusamaBangou2)))
    window.scrollTo({top: 0,behavior: 'smooth',})
    handleError("yubinBangou","requiredError",false)
    handleError("yubinBangou","rangeError",false)
    handleError("yubinBangou","notFoundAddressError",false)
    handleError("yubinBangou","notKyusyuError",false)

    //API連携
    if(errCount === 0){
      await setIsLoading(true)
      await setLoadLabel("送信中")
      //API連携
      okyakusamaBangou1 += getValue("okyakusamaBangou1_6")
      okyakusamaBangou2 += getValue("okyakusamaBangou2_6")
      okyakusamaBangou1.length===19 ? okyakusamaBangou1=okyakusamaBangou1 : okyakusamaBangou1=""
      okyakusamaBangou2.length===19 ? okyakusamaBangou2=okyakusamaBangou2 : okyakusamaBangou2=""
      let form = {
        "genzaiOtukainoKeitaiDenwaBangou":String(values.genzaiOtukainoDenwaBangou1.value) + String(values.genzaiOtukainoDenwaBangou2.value) + String(values.genzaiOtukainoDenwaBangou3.value),
        "mousikomiSyaKbn":values.mousikomiSyaKbn.value == "" ? "00":values.mousikomiSyaKbn.value, //空なら本人"00"を設定
        "keiyakuSyaTonoGokankei":values.keiyakuSyaTonoGokankei.value,
        "mousikomiSyaMeigi":values.mousikomiSyaMeigi.value,
        "keiyakuMeigiKana":values.keiyakuSyaMeigiKana.value,
        "yubinBangou":values.yubinBangou.value,
        "toDouHuKenCode":values.toDouHuKen.value,
        "toDouHuKenLabel":values.toDouHuKen.label,
        "siKuTyouSonCode":values.siKuTyouSon.value,
        "siKuTyouSonLabel":values.siKuTyouSon.label,
        "azaTyoumeCode":values.azaTyoume.value,
        "azaTyoumeLabel":values.azaTyoume.label,
        "banTiGou":values.bantiGou.value,
        "tatemonoMei":values.tatemonoMei.value,
        "heyaBangou":values.heyaBangou.value,
        "okyakusamaBangou1":okyakusamaBangou1,
        "okyakusamaBangou2":okyakusamaBangou2,
        "gotourokunoDenwaBangou":values.gotourokuNoDenwaBangou1.value + "-" + values.gotourokuNoDenwaBangou2.value + "-" + values.gotourokuNoDenwaBangou3.value,
        "room_id":room_id,
        "toiawaseNaiyou":toiawaseNaiyou
      }
      let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB001",form)
      if(result.status_code === "200"){
          setValues({ ...values, "systemError": false })
          liff.closeWindow()
          window.close()
      }else{
          setValues({ ...values, "systemError": true });
          
      }
    }
    await setIsLoading(false)
  }


  //以下ハンドラー
  function handleValue(name, value) {
    updateValues({ [name]: { value } },setValues);
  }
  function handleLabel(name, value) {
    updateValues({ [name]: { label: value } },setValues);
  }
  function handleError(name, error, bool) {
    const updatedValues = { [name]: { [error]: bool } };
    if (bool) errCount += 1;
    updateValues(updatedValues,setValues);
  }
  function tdhChange(name,value) {
    let findTdhk = allTodouHuKen.find(item => item.value === value)
    const resetSiKuTyouSonAndAzaTyoume = () => {
      handleValue("siKuTyouSon", "");
      handleLabel("siKuTyouSon", "");
      handleValue("azaTyoume", "");
      handleLabel("azaTyoume", "");
    };
    handleValue(name,value)
    handleLabel(name,value === "" ? value :findTdhk.label)
    resetSiKuTyouSonAndAzaTyoume()
    handleValue("siKuTyouSonOption",serchSiKuTyouSon(value,sikuTyouSonJsonData))
  }
  function sktsChange(name,value,tdhkValue=values.toDouHuKen.value) {
    let findSts = sikuTyouSonJsonData[tdhkValue].find(item => item[1] === value)
    handleValue(name,value)
    handleLabel(name,value === "" ? value :findSts[0])
    handleValue("azaTyoume","")
    handleLabel("azaTyoume","")
    handleValue("azaTyoumeOption",serchAzaTyouMe(tdhkValue+value,azaTyoumeJsonData))
  }
  function atmChange(name,value) {
    let findAtm = azaTyoumeJsonData[values.toDouHuKen.value+values.siKuTyouSon.value].find(item => item[1] === value)
    handleValue(name,value)
    handleLabel(name,value === "" ? value :findAtm[0])
  }
  function getValue(name){
    return values[name].value
  }
  
  


  return (
    <>
    {fetchInitialDataFinish &&
      <Layout isLoading={isLoading} label={loadLabel} headerLabel={displayName.A001}>
        <SectionWithText label={"ご契約を特定するため、\n以下の情報を入力ください。"}>
          <FormInputWrapper label="SMS送信先電話番号を入力してください。" required={true} annotation="※ 本人認証の際に、SMS（ショートメッセージサービス）を利用しますので、お手元にある携帯電話番号を入力ください。" multi={true}>
            <p><RenderPhoneNumberInputs
              formDataList={[values.genzaiOtukainoDenwaBangou1,values.genzaiOtukainoDenwaBangou2,values.genzaiOtukainoDenwaBangou3]}
              handleValue={handleValue}
              name="genzaiOtukainoDenwaBangou"
            >
            </RenderPhoneNumberInputs></p>
            <FormValidationError formDataList={[values.genzaiOtukainoDenwaBangou1,values.genzaiOtukainoDenwaBangou2,values.genzaiOtukainoDenwaBangou3]}></FormValidationError>
          </FormInputWrapper>

          {!isSelf && <FormInputWrapper label="申込者情報を入力してください。">
            <dl>
              <div className=""><p className="">1 契約者とのご関係を選択してください。</p></div>
            </dl>
            <TermInputPair label="申込者区分" required={true}>
              <CommonSelect name="mousikomiSyaKbn" options={A001_mousikomiSyaKubun} formData={values.mousikomiSyaKbn} handleValue={handleValue} />
            </TermInputPair>
            <FormValidationError formDataList={[values.mousikomiSyaKbn]} type="select"></FormValidationError>
            {["02", "99"].includes(values.mousikomiSyaKbn.value) && <>
            <TermInputPair label="契約者とのご関係" required={true}>
              <InputText name="keiyakuSyaTonoGokankei" formData={values.keiyakuSyaTonoGokankei} handleValue={handleValue}></InputText>
            </TermInputPair>
            <FormValidationError formDataList={[values.keiyakuSyaTonoGokankei]}></FormValidationError> </>}
            <dl>
              <div className=""><p className="">2 申込者のお名前を入力してください。</p></div>
            </dl>
            <TermInputPair label="申込者名義" required={true}>
              <InputText name="mousikomiSyaMeigi" formData={values.mousikomiSyaMeigi} handleValue={handleValue}></InputText>
            </TermInputPair>
            <FormValidationError formDataList={[values.mousikomiSyaMeigi]}></FormValidationError>
          </FormInputWrapper>}

          <FormInputWrapper label="契約名義カナ（例：キュウデン　タロウ）" required={true}>
            <p><InputText name="keiyakuSyaMeigiKana" formData={values.keiyakuSyaMeigiKana} handleValue={handleValue}></InputText></p>
            <FormValidationError formDataList={[values.keiyakuSyaMeigiKana]}></FormValidationError>
          </FormInputWrapper>
          <FormInputWrapper label="使用場所住所">
            <TermInputPair label="郵便番号" annotation="※ハイフンなしで検索ください" className="zip">
              <ZipCodeSearch name="yubinBangou" formData={values.yubinBangou} searchClick={searchClick} handleValue={handleValue}></ZipCodeSearch>
            </TermInputPair>
            <FormValidationError formDataList={[values.yubinBangou]} type={"yubinBangou"}></FormValidationError>
            <dl>
              <div className="explanation-box"><p className="explanation-box_txt">検索できない場合は、以下を直接入力してください</p></div>
            </dl>
            <TermInputPair label="都道府県" required={true}>
              <CustomSelect name="toDouHuKen"options={kyusyuTodouHuKen} formData={values.toDouHuKen} handleValue={tdhChange} disabled={false}/>
            </TermInputPair>
            <FormValidationError formDataList={[values.toDouHuKen]} type={"addressSelect"}></FormValidationError>
            <TermInputPair label="市区町村" required={true}>
              <CustomSelect name="siKuTyouSon"options={values.siKuTyouSonOption.value} formData={values.siKuTyouSon} handleValue={sktsChange} disabled={values.toDouHuKen.value === ""} />
            </TermInputPair>
            <FormValidationError formDataList={[values.siKuTyouSon]} type={"addressSelect"}></FormValidationError>
            <TermInputPair label="字丁目" required={true}>
              <CustomSelect name="azaTyoume"options={values.azaTyoumeOption.value} formData={values.azaTyoume} handleValue={atmChange} disabled={values.siKuTyouSon.value === ""} />
            </TermInputPair>
            <FormValidationError formDataList={[values.azaTyoume]} type={"addressSelect"}></FormValidationError>
            <TermInputPair label="番地号">
              <InputText name="bantiGou" formData={values.bantiGou} handleValue={handleValue}></InputText>
            </TermInputPair>
            <TermInputPair label="建物名">
              <InputText name="tatemonoMei" formData={values.tatemonoMei} handleValue={handleValue}></InputText>
            </TermInputPair>
            <TermInputPair label="部屋番号">
              <InputText name="heyaBangou" formData={values.heyaBangou} handleValue={handleValue}></InputText>
            </TermInputPair>
            <p className="annotation">マンション・アパートの場合は、建物名、部屋番号を必ず入力してください</p>
          </FormInputWrapper>
          <FormInputWrapper label="契約者電話番号" annotation="※ 契約の特定に使用しますので、ご連絡先として過去にお申し出いただいている電話番号を入力してください。" required={true}>
              {isSelf && 
                <div className="radio">
                  <InputRadio
                      name="denwaBangouRadio"
                      radioInfo={phoneNumManualInputSelected}
                      formData={values.denwaBangouRadio}
                      handleValue={handleValue}>
                  </InputRadio>
                </div>
              }
              <p><RenderPhoneNumberInputs
                formDataList={[values.gotourokuNoDenwaBangou1,values.gotourokuNoDenwaBangou2,values.gotourokuNoDenwaBangou3]}
                handleValue={handleValue}
                name="gotourokuNoDenwaBangou"
                disabled={values.denwaBangouRadio.value === "1"}>
              </RenderPhoneNumberInputs></p>
              <FormValidationError formDataList={[values.gotourokuNoDenwaBangou1,values.gotourokuNoDenwaBangou2,values.gotourokuNoDenwaBangou3]}></FormValidationError>
            </FormInputWrapper>
        </SectionWithText>

        {!customerNumberVisible && 
          <SectionWithText label={"お客さま番号を入力ください。"}>
            <p className="annotation">※ 温水器・動力など、複数の契約のお手続きを ご希望の場合は、お客さま番号（２契約目）も入力してください。</p>
              <FormInputWrapper label="お客さま番号（1契約目）" multi={true}>
                <p><CustomerNumber name="okyakusamaBangou1" customNumbers={[values.okyakusamaBangou1_1,values.okyakusamaBangou1_2,values.okyakusamaBangou1_3,values.okyakusamaBangou1_4,values.okyakusamaBangou1_5,values.okyakusamaBangou1_6]} handleValue={handleValue}>
                </CustomerNumber></p>
                <p className="annotation txt_placeholder"><span className="fg02">２桁</span><span className="fg03">3桁</span><span className="fg03">3桁</span><span className="fg02">２桁</span><span className="fg07">7桁</span><span className="fg02">２桁</span></p>
                <FormValidationError formDataList={[values.okyakusamaBangou1_1,values.okyakusamaBangou1_2,values.okyakusamaBangou1_3,values.okyakusamaBangou1_4,values.okyakusamaBangou1_5]}></FormValidationError>
            </FormInputWrapper>
            <FormInputWrapper label="お客さま番号（2契約目）" multi={true}>
                <p><CustomerNumber name="okyakusamaBangou2" customNumbers={[values.okyakusamaBangou2_1,values.okyakusamaBangou2_2,values.okyakusamaBangou2_3,values.okyakusamaBangou2_4,values.okyakusamaBangou2_5,values.okyakusamaBangou2_6]} handleValue={handleValue}>
                </CustomerNumber></p>
                <p className="annotation txt_placeholder"><span className="fg02">２桁</span><span className="fg03">3桁</span><span className="fg03">3桁</span><span className="fg02">２桁</span><span className="fg07">7桁</span><span className="fg02">２桁</span></p>
                <FormValidationError formDataList={[values.okyakusamaBangou2_1,values.okyakusamaBangou2_2,values.okyakusamaBangou2_3,values.okyakusamaBangou2_4,values.okyakusamaBangou2_5]}></FormValidationError>
            </FormInputWrapper>
          </SectionWithText>
        }
        <Submit label="送信" onClick={submit} error={values.systemError}></Submit>
      </Layout>
    }
    </>
  );
}

export const A001_mousikomiSyaKubun = [
    { value: '01', label: '配偶者'},
    { value: '02', label: '家族'},
    { value: '99', label: 'その他'},
]

export const C007_mousikomiSyaKubun = [
    { value: '現契約者本人', label: '現契約者本人'},
    { value: '配偶者', label: '配偶者'},
    { value: '家族', label: '家族'},
    { value: 'その他', label: 'その他'}
]

export const phoneNumManualInputSelected = [
    {label:"SMS送信先電話番号と同じ",value:"1",id:"r01"},
    {label:"電話番号の直接入力",value:"2",id:"r02"}
]

export const B002_radio = [
    {label:"口座振替",value:"口座振替",id:"r01"},
    {label:"クレジットカード支払い",value:"クレジットカード支払い",id:"r02"},
    {label:"振込票",value:"振込票",id:"r03"},
    {label:"SMS決済",value:"SMS決済",id:"r04"}
]

export const mousikomiSyaKubun = [
    { value: '現契約者本人', label: '現契約者本人'},
    { value: '配偶者', label: '配偶者'},
    { value: '家族・親族', label: '家族・親族'},
    { value: 'その他', label: 'その他'},
]

export const mousikomiSyaDenwaBangouKubun = [
    { value: '携帯', label: '携帯'},
    { value: 'その他', label: 'その他'},
]

export const syomenHakkounoYouhi = [
    { value: '必要', label: '必要'},
    { value: '不要', label: '不要'},
]

export const saiHakkouRiyu = [
    { value: '紛失', label: '紛失'},
    { value: '未着', label: '未着'},
    { value: '期限切れ', label: '期限切れ'},
]

export const osiharaiHouhou = [
    { value: '口座振替', label: '口座振替'},
    { value: 'クレジットカード払い', label: 'クレジットカード払い'},
    { value: 'SMS決済', label: 'SMS決済'},
    { value: '振込票', label: '振込票'},
]


export const errorMessage = {
    required:"ご入力ください",
    requiredSelect:"選択してください",
    duplicateMonth:"対象年月1と異なる年月を選択してください",
    shortRange:"桁数が足りません",
    kanaFormat:"カナでご入力ください",
    requiredAddress:"郵便番号から検索、または選択してください",
    zenkakuFormat:"全角でご入力ください",
    phoneFormat:"携帯電話番号をご入力ください",
    A001_CorrelationRequired:"1契約目からご入力ください",

    //郵便番号検索
    //-----------
    requiredYubinBangou:"郵便番号を入力してください",
    shortRangeYubinBangou:"7桁でご入力ください",
    notKyusyu:"九州地方の郵便番号をご入力ください",
    notFoundAddress:"該当する住所が検索できませんでした",

    //認証系
    //------------
    authFail:"認証に失敗しました",
    authExpired:"有効期限切れです。「認証コードを再送信する」から新しい認証コードを取得してください。"
}

export const displayName = {
    A001:"ご契約情報",
    A002:"電気料金のお支払い情報",
    A003:"お支払者情報",
    A004:"電気設備情報",
    B001:"支払期限日・振替日の確認",
    B002:"支払状況の確認",
    C001:"契約名義変更",
    C002:"ご登録の電話番号変更",
    C003:"郵便物の送付先住所変更",
    C004:"書面での電気ご使用量のお知らせ発行または停止",
    C005:"電気ご使用量のお知らせの再発行",
    C006:"振込票紛失等でお困りの方",
    C007:"ご使用開始",
    D001:"情報入力",
    E001:"認証コード入力",
    G001:"SMS認証用携帯電話番号訂正",
    K001:"回答内容",
}
import React, { useEffect } from "react";
import { FORM_B002 } from "../config/form";
import { displayName } from "../config/config";
import {required,isDuplication} from "../util/validate";
import { convertMinDate,convertMaxDate,call_api,updateValues} from "../util/util";
import { Layout } from "../Layout/Layout";
import { FormInputWrapper } from "../Layout/FormInputWrapper";
import { SectionWithText } from "../Layout/SectionWithText";
import { FormValidationError } from "../components/FormValidationError";
import { PaymentResult } from "../components/PaymentResult";
import { SystemError } from "../components/SystemError";
import liff from '@line/liff';

export function B002(props) {
    document.title = displayName.B002;
    const room_id = props.roomId
    const [values, setValues] = React.useState(FORM_B002);
    const [fetchInitialDataFinish,setFetchInitialDataFinish] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [paymentAmount1, setPaymentAmount1] = React.useState("");
    const [paymentStatus1, setPaymentStatus1] = React.useState("");
    const [paymentAmount2, setPaymentAmount2] = React.useState("");
    const [paymentStatus2, setPaymentStatus2] = React.useState("");
    const [searchedDate, setSearchedDate] = React.useState("");
    const [alreadyQueried,setAlreadyQueried] = React.useState(false);
    let errCount = 0


    useEffect(() => {
        fetchInitialData(props.roomId);
    },[]);

    //初期表示処理
    async function fetchInitialData(_room_id){
        let form = {"room_id":_room_id,}
        let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo",form)
        if(result.status_code==="200" && result.is_room_close){
            await setAlreadyQueried(true)
            alert("すでに照会済のため、再照会はできません。")
            if(result.extended_room_tag_info?.shri_jk_syokai_result !== ""){
                let [title, savedTaisyouTuki1, savedTaisyouTuki2,queryResult] = result.extended_room_tag_info?.shri_jk_syokai_result.split('\n').map(item => item.split(":")[1]);
                let [paymentAmount1,paymentStatus1,paymentAmount2,paymentStatus2,searchedDate] = queryResult.split(",")
                await handleValue("taisyouTuki1",savedTaisyouTuki1.replace("/","-"))
                await handleValue("taisyouTuki2",savedTaisyouTuki2.replace("/","-"))
                await setPaymentAmount1(paymentAmount1)
                await setPaymentStatus1(paymentStatus1)
                await setPaymentAmount2(paymentAmount2)
                await setPaymentStatus2(paymentStatus2)
                await setSearchedDate(searchedDate)
            }
        }
        await setFetchInitialDataFinish(true)
        return
    }

    //送信処理
    async function submit(){
        //入力チェック
        errCount = 0
        handleError("taisyouTuki1","requiredError",required(values.taisyouTuki1.value))
        handleError("taisyouTuki2","duplicationError",isDuplication(values.taisyouTuki1.value,values.taisyouTuki2.value))
        window.scrollTo({top: 0,behavior: 'smooth',})

        //API連携
        if(errCount === 0){
            await setLoading(true)
            //API連携
            let form = {
                "taisyouTuki1":values.taisyouTuki1.value,
                "taisyouTuki2":values.taisyouTuki2.value,
                "display_id":"B002",
                "room_id":room_id
            }
            let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB007",form)
            if(result.status_code === "200"){
                if (result.api_error){
                    liff.closeWindow()
                    window.close()
                    return
                }

                await setPaymentAmount1(result.payment_amount1)
                await setPaymentStatus1(result.payment_status1)
                await setPaymentAmount2(result.payment_amount2)
                await setPaymentStatus2(result.payment_status2)
                await setSearchedDate(result.searched_date)
                await setValues({ ...values, "systemError": false })
                if(result.searched_date){
                    await setAlreadyQueried(true)
                }
            }else{
                await setValues({ ...values, "systemError": true });
            }
        }
        await setLoading(false)
    }

    //以下ハンドラー
    function handleCalendarBlur(e) {
        if(e.target.value === "") return;
        let date = convertMinDate(e.target.value,values[e.target.name].min)
        date = convertMaxDate(date,values[e.target.name].max)
        handleValue(e.target.name,date)
    }
    function handleCalendarChange(e) {
        handleValue(e.target.name,e.target.value)
        setTimeout(()=>{
            e.target.defaultValue = "";
          }, 100);
    }
    function handleCalendarClick(e) {
        e.target.defaultValue = "";
    }
    function handleValue(name, value) {
        updateValues({ [name]: { value } },setValues);
      }
    function handleError(name, error, bool) {
        const updatedValues = { [name]: { [error]: bool } };
        if (bool) errCount += 1; 
        updateValues(updatedValues,setValues);
    }
    
    return (
    <>
        {fetchInitialDataFinish && <Layout headerLabel={displayName.B002} isLoading={loading} label="送信中">
            <SectionWithText label="照会したい年月を選択ください。">
                <FormInputWrapper label="対象年月1" required={true} multi={true} calendar={true}>
                    <p className="calendar">
                        <label className="calendar">
                            <input type="month" className={values.taisyouTuki1.requiredError ? "is-invalid" :""} onChange={handleCalendarChange} onClick={handleCalendarClick} onBlur={handleCalendarBlur} value={values.taisyouTuki1.value} name="taisyouTuki1" max={values.taisyouTuki1.max} min={values.taisyouTuki1.min} />
                        </label>
                    </p>
                    <FormValidationError formDataList={[values.taisyouTuki1]} type="calendar"></FormValidationError>
                </FormInputWrapper>
                <FormInputWrapper label="対象年月2" multi={true} calendar={true}>
                    <p className="calendar">
                        <label className="calendar">
                            <input type="month" className={values.taisyouTuki2.duplicationError ? "is-invalid" :""} onChange={handleCalendarChange} onClick={handleCalendarClick} onBlur={handleCalendarBlur} value={values.taisyouTuki2.value} name="taisyouTuki2" max={values.taisyouTuki2.max} min={values.taisyouTuki2.min} />
                        </label>
                    </p>
                    <FormValidationError formDataList={[values.taisyouTuki2]} type="calendar"></FormValidationError>
                </FormInputWrapper>
                <div className="layout-centering mt8">
                    <input type="button" value="照会" className={alreadyQueried ? "secondaryMediumDisabledBtn w160Btn":"secondaryMediumBtn w160Btn"} onClick={submit} disabled={alreadyQueried} />
                </div>
                <PaymentResult paymentAmount1={paymentAmount1} paymentStatus1={paymentStatus1} paymentAmount2={paymentAmount2} paymentStatus2={paymentStatus2} searchedDate={searchedDate} alreadyQueried={alreadyQueried}/>
            </SectionWithText>
            <section className="submit payment">
            <ul>
                <li>
                    <button type="submit" onClick={() => {liff.closeWindow();window.close();}}className="secondaryBtn"><span className="btn_inner">閉じる</span></button>
                </li>
            </ul>
            {values.systemError && <SystemError />}
            </section>
        </Layout>}
    </>
    )}
import React from "react";

export function InputTel({name,formData,handleValue,className="",placeHolder="",disabled=false}) {
    const orgClass = formData.requiredError || formData.rangeError || formData.phoneFormatError || formData.notKyusyuError || formData.notFoundAddressError || formData.rangeErrorSubmit ? "is-invalid "+className : className;
    function handleInputChange(e) {
        const re = /^[0-9\b]+$/;
        //正規表現に一致したもののみ設定
        if (e.target.value === '' || re.test(e.target.value)) {
          handleValue(e.target.name,e.target.value)
        }
    }
    return (
        <>
            <input
                type="tel"
                name={name}
                maxLength={formData.maxLength}
                onChange={handleInputChange}
                value={formData.value}
                className={orgClass}
                placeholder={placeHolder}
                disabled={disabled} />
        </>
    );
}
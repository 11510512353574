export function required(str){
    return str === "" || str === "undefind"
}

export function isDuplication(str1,str2){
    return str1!=="" && str1===str2
}

export function isShortRange(str,length){
    if(str === "") return false;
    return str.length < length
}

export function isRange(str,length){
    if(str === "") return false;
    return str.length === length
}

export function isKana(str){
    if(str === "") return false;
    const re = /^[ァ-ヶー　ｦ-ﾟ]+$/;
    return !(re.test(str))
}

export function isZenkaku(str){
    if(str === "") return false;
    const re = /^[^\x01-\x7E\uFF61-\uFF9F]+$/;
    return !(re.test(str))
}

export function isPhoneFormat(str){
    if(str === "") return false;
    return !(str === "070" || str === "080" || str === "090")
}

export function correlationRequired(targetStr,comparsionStr){
    return targetStr === "" && comparsionStr !== ""
}
import {required,isShortRange} from "../util/validate";
import { kyusyuKenList} from "../config/address";
//import {postObjList} from "../config/post";
import axios from 'axios'

export async function call_api(url,param){
  return await axios.post(url,param)
      .then((response) => {
          return response.data
      })
      .catch ((error) => {
        console.log("InternalError")
        return {"status_code":"502"}
      }); 
}



//住所系
//-----------------------------------
export async function getPostJsonData(setStatePost,setStateSikuTyouSon,setStateAzaTyoume){
  //ブラウザキャッシュを参照しない様に1時間ごとにリクエスト内容を変更
  let date = new Date()
  let param = date.getFullYear+date.getMonth+date.getDate+date.getHours
  let post_res = await axios.get(process.env.REACT_APP_ADDESS_INFO_URL+"post.json?"+param);
  let siKuTyouSon_res = await axios.get(process.env.REACT_APP_ADDESS_INFO_URL+"sikutyouson.json?"+param);
  let azaTyoume_res = await axios.get(process.env.REACT_APP_ADDESS_INFO_URL+"azaTyoume.json?"+param);
  setStatePost(post_res.data)
  setStateSikuTyouSon(siKuTyouSon_res.data)
  setStateAzaTyoume(azaTyoume_res.data)
};



export function serchSiKuTyouSon(toDouHuKenMei,siKuTyouSonObjList){
  if(Object.keys(siKuTyouSonObjList).length === 0){return [{value:"",label:"検索中..."}]}
  let siKuTyouSonList = [{value:"",label:"未選択"}]
  if(siKuTyouSonObjList[toDouHuKenMei]===undefined){
    return siKuTyouSonList
  }
  siKuTyouSonObjList[toDouHuKenMei].forEach(sikutyouson =>{
    let obj = {value:"",label:""}
    obj["value"] = sikutyouson[1]
    obj["label"] = sikutyouson[0]
    siKuTyouSonList.push(obj)
  })
  return siKuTyouSonList
}

export function serchAzaTyouMe(siKuTyouSonMei,azaTyoumeObjList){
  if(Object.keys(azaTyoumeObjList).length === 0){return [{value:"",label:"検索中..."}]}
  let azaTyoumeList = [{value:"",label:"未選択"}]
  if(azaTyoumeObjList[siKuTyouSonMei]===undefined){
    return azaTyoumeList
  }
  azaTyoumeObjList[siKuTyouSonMei].forEach(azaTyoume =>{
    let obj = {value:"",label:""}
    obj["value"] = azaTyoume[1]
    obj["label"] = azaTyoume[0]
    azaTyoumeList.push(obj)
  })
  return azaTyoumeList
}

export function addressSearchFromPostNum(postNomber,postObjList,atmData){

  if(postObjList[postNomber]===undefined){
    return {}
  }

  let postList = [{value:"",label:"未選択"}]
  postObjList[postNomber][2].forEach(azaTyoume =>{
    let obj = {value:"",label:""}
    let findAtm = atmData[postObjList[postNomber][0]+postObjList[postNomber][1]].find(item => item[1] === azaTyoume)
    obj["value"] = findAtm[1]
    obj["label"] = findAtm[0]
    postList.push(obj)
  })
  let result = {"prefecture_name":"","municipalitie_name":"","addressList":[]}
  result.prefecture_name = postObjList[postNomber][0]
  result["municipalitie_name"] = postObjList[postNomber][1]
  result["addressList"] = postList
  return result
}

export function addressSearch(values,handleValue,handleError,sikuTyouSonJsonData,postJsonData,kyusyuOnly){
  //const value = values["yubinBangou"].value
  const value = values.value    
  if(required(value)){
    handleError("yubinBangou","requiredError",true)
    handleError("yubinBangou","rangeError",false)
    handleError("yubinBangou","notFoundAddressError",false)
    handleError("yubinBangou","notKyusyuError",false)
    return
  }else{
    handleError("yubinBangou","requiredError",false)
  }
  if(isShortRange(value,7)){
    handleError("yubinBangou","rangeError",true)
    handleError("yubinBangou","notFoundAddressError",false)
    handleError("yubinBangou","notKyusyuError",false)
    return
  }else{
    handleError("yubinBangou","rangeError",false)
  }

  const address = addressSearchFromPostNum(value,postJsonData)
  if(Object.keys(address).length  === 0){
    handleError("yubinBangou","notFoundAddressError",true)
    handleError("yubinBangou","notKyusyuError",false)
    return
  }else{
    handleError("yubinBangou","notFoundAddressError",false)
  }

  if(kyusyuOnly){
    if(kyusyuKenList.includes(address.prefecture_name)){
      handleError("yubinBangou","notKyusyuError",false)
    }else{
      handleError("yubinBangou","notKyusyuError",true)
      return
    }
  }

  handleValue("toDouHuKen",address.prefecture_name)
  handleValue("siKuTyouSon",address.municipalitie_name)
  handleValue("azaTyoume","")
  handleValue("siKuTyouSonOption",serchSiKuTyouSon(address.prefecture_name,sikuTyouSonJsonData))
  handleValue("azaTyoumeOption",address.addressList)
}

//日付系
//-----------------------------------
export function getTodayFormat_yyyy_mm_dd(){
    let dt = new Date();
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth()+1)).slice(-2);
    let d = ("00" + (dt.getDate())).slice(-2);

    return [y,m,d].join("-")
}

export function getTodayFormat_yyyy_mm(){
    let dt = new Date();
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth()+1)).slice(-2);

    return [y,m].join("-")
}

export function getYearAgoFormat_yyyy_mm(){
    let dt = new Date();
    let month = dt.getMonth()+1;
    dt.setMonth(month -12)
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth()+1)).slice(-2);

    return [y,m].join("-")
}

export function convertMinDate(date,minDate){
    return date < minDate ? minDate : date;
}

export function convertMaxDate(date,maxDate){
    return date > maxDate ? maxDate : date;
}


//ルーム情報抽出系
export function isOperater(mbaRoomInfo){
  let roomStatus = mbaRoomInfo.extra
  let isRoomFinish = ["finish", "close"].includes(roomStatus)
  let isOperator = mbaRoomInfo.tag9 === "0"


  return !isRoomFinish && isOperator
}
export function isBot(mbaRoomInfo){
  let roomStatus = mbaRoomInfo.extra
  let isRoomFinish = ["finish", "close"].includes(roomStatus)
  let isBot = mbaRoomInfo.tag9 === "1"


  return !isRoomFinish && isBot
}

//ハンドラー
export function updateValues(updatedFields,setValues) {
  setValues(prevValues => {
    const updatedValues = { ...prevValues };
    for (const fieldName in updatedFields) {
      if (Object.prototype.hasOwnProperty.call(updatedFields, fieldName)) {
        updatedValues[fieldName] = {
          ...updatedValues[fieldName],
          ...updatedFields[fieldName]
        };
      }
    }
    return updatedValues;
  });
}
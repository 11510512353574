import React from "react";

export function FormInputWrapper({children,label="",required=false,annotation="",multi=false,calendar=false}){
    return(
        <>
            <div className={`formSet ${multi ? 'multiNum':''} ${calendar ? 'calendar':''}`}>
              <h3 className={required ? 'required':''}>{label}</h3>
              {annotation && <p className="annotation">{annotation}</p>}
              {children}
            </div>
        </>
    )
}
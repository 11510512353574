import React, { useEffect } from "react";
import { FORM_C001 } from "../config/form";
import { displayName } from "../config/config";
import {required,isShortRange,isZenkaku,correlationRequired,isDuplication} from "../util/validate";
import { convertMinDate,convertMaxDate,call_api,serchSiKuTyouSon,serchAzaTyouMe,getPostJsonData,addressSearchFromPostNum,isOperater,updateValues} from "../util/util";
import { allTodouHuKen} from "../config/address";
import { Layout } from "../Layout/Layout";
import { FormInputWrapper } from "../Layout/FormInputWrapper";
import { SectionWithText } from "../Layout/SectionWithText";
import {TermInputPair} from "../Layout/TermInputPair"
import { InputText } from "../parts/InputText";
import { CustomSelect } from "../parts/CustomSelect";
import { ZipCodeSearch } from "../components/ZipCodeSearch";
import { FormValidationError } from "../components/FormValidationError";
import { Submit } from "../components/Submit";
import liff from '@line/liff';

export function C005(props) {
    document.title = displayName.C005;
    const room_id = props.roomId
    const [values, setValues] = React.useState(FORM_C001);
    const [postJsonData, setPostJsonData] = React.useState({});
    const [sikuTyouSonJsonData, setSikuTyouSonJsonData] = React.useState({});
    const [azaTyoumeJsonData, setAzaTyoumeJsonData] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(false);
    const [isSearchClick,setIsSearchClick] = React.useState(false);
    const [isSubmitDisabled,setIsSubmitDisabled] = React.useState(true);
    let errCount = 0

    useEffect(() => {
        getPostJsonData(setPostJsonData,setSikuTyouSonJsonData,setAzaTyoumeJsonData)
        fetchInitialData(room_id)
      },[]);

    //住所データ三つを監視対象としているが同期処理で返却されるためいずれも同一タイミングで変更検知
    useEffect(() => {
        if(Object.keys(postJsonData).length){
        handleValue("siKuTyouSonOption",serchSiKuTyouSon(values.toDouHuKen.value,sikuTyouSonJsonData))
        handleValue("azaTyoumeOption",serchAzaTyouMe(values.toDouHuKen.value+values.siKuTyouSon.value,azaTyoumeJsonData))
        if(isSearchClick){
            searchClick(values.yubinBangou.value)
            setIsLoading(false)
        }
        } 
    }, [postJsonData,sikuTyouSonJsonData,azaTyoumeJsonData]);

    //初期表示処理
    async function fetchInitialData(_room_id){
        let form = {"room_id":_room_id,}
        let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "getRoomInfo",form)
        if(result.status_code==="200"){
            await setIsSubmitDisabled(!isOperater(result.roomInfo))
        }
        return result
    }

    //郵便番号検索処理
    function searchClick(){
        const yubinBangou = values.yubinBangou.value;
        const isRequiredError = required(yubinBangou);
        const isRangeError = isShortRange(yubinBangou, 7);
    
        handleError("yubinBangou","requiredError",isRequiredError)
        handleError("yubinBangou","rangeError",isRangeError)
    
        if(isRequiredError || isRangeError)return;
        if(!(Object.keys(postJsonData).length)) {setIsSearchClick(true);setIsLoading(true);return;}
    
        //郵便番号で検索
        const address = addressSearchFromPostNum(yubinBangou,postJsonData,azaTyoumeJsonData)
    
        const notFound = Object.keys(address).length === 0
    
        handleError("yubinBangou","notFoundAddressError",notFound)
        if(notFound)return;
    
        tdhChange("toDouHuKen", address.prefecture_name)
        sktsChange("siKuTyouSon",address.municipalitie_name,address.prefecture_name)
        handleValue("azaTyoumeOption",address.addressList)
    }

    //送信処理
    async function submit(){
        //入力チェック
        errCount = 0
        handleError("taisyouTuki1","requiredError",required(values.taisyouTuki1.value))
        handleError("taisyouTuki2","duplicationError",isDuplication(values.taisyouTuki1.value,values.taisyouTuki2.value))
        handleError("yubinBangou","rangeErrorSubmit",isShortRange(values.yubinBangou.value,7))
        handleError("atena","hankakuError",isZenkaku(values.atena.value))

        handleError("siKuTyouSon","requiredError",correlationRequired(values.siKuTyouSon.value,values.toDouHuKen.value))
        handleError("azaTyoume","requiredError",correlationRequired(values.azaTyoume.value,values.toDouHuKen.value+values.siKuTyouSon.value))
        
        handleError("yubinBangou","requiredError",false)
        handleError("yubinBangou","rangeError",false)
        handleError("yubinBangou","notFoundAddressError",false)
        handleError("yubinBangou","notKyusyuError",false)
        window.scrollTo({top: 0,behavior: 'smooth',})
        if(errCount !== 0){return;}
        //API連携
        let form = {
            "taisyouTuki1":values.taisyouTuki1.value,
            "taisyouTuki2":values.taisyouTuki2.value,
            "atena":values.atena.value,
            "yubinBangou":values.yubinBangou.value,
            "toDouHuKen":values.toDouHuKen.label,
            "siKuTyouSon":values.siKuTyouSon.label,
            "azaTyoume":values.azaTyoume.label,
            "banTiGou":values.bantiGou.value,
            "tatemonoMei":values.tatemonoMei.value,
            "heyaBangou":values.heyaBangou.value,
            "display_id":"C005",
            "room_id":room_id
            }
        let result = await call_api(process.env.REACT_APP_MBA_CUSTMISE_URL + "PB002",form)
        if(result.status_code === "200"){
            setValues({ ...values, "systemError": false })
            liff.closeWindow()
            window.close()
        }else{
            setValues({ ...values, "systemError": true });
        }
    }

    //以下ハンドラー
    function handleCalendarBlur(e) {
        if(e.target.value === "") return;
        let date = convertMinDate(e.target.value,values[e.target.name].min)
        date = convertMaxDate(date,values[e.target.name].max)
        handleValue(e.target.name,date)
    }
    function handleCalendarChange(e) {
        handleValue(e.target.name,e.target.value)
        setTimeout(()=>{
            e.target.defaultValue = "";
        }, 100);
    }
    function handleCalendarClick(e) {
        e.target.defaultValue = "";
    }
    function tdhChange(name,value) {
        let findTdhk = allTodouHuKen.find(item => item.value === value)
        const resetSiKuTyouSonAndAzaTyoume = () => {
            handleValue("siKuTyouSon", "");
            handleLabel("siKuTyouSon", "");
            handleValue("azaTyoume", "");
            handleLabel("azaTyoume", "");
        };
        handleValue(name,value)
        handleLabel(name,value === "" ? value :findTdhk.label)
        resetSiKuTyouSonAndAzaTyoume()
        handleValue("siKuTyouSonOption",serchSiKuTyouSon(value,sikuTyouSonJsonData))
    }
    function sktsChange(name,value,tdhkValue=values.toDouHuKen.value) {
        let findSts = sikuTyouSonJsonData[tdhkValue].find(item => item[1] === value)
        handleValue(name,value)
        handleLabel(name,value === "" ? value :findSts[0])
        handleValue("azaTyoume","")
        handleLabel("azaTyoume","")
        handleValue("azaTyoumeOption",serchAzaTyouMe(tdhkValue+value,azaTyoumeJsonData))
    }
    function atmChange(name,value) {
        let findAtm = azaTyoumeJsonData[values.toDouHuKen.value+values.siKuTyouSon.value].find(item => item[1] === value)
        handleValue(name,value)
        handleLabel(name,value === "" ? value :findAtm[0])
    }

    function handleValue(name, value) {
        updateValues({ [name]: { value } },setValues);
    }
    function handleLabel(name, value) {
        updateValues({ [name]: { label: value } },setValues);
    }
    function handleError(name, error, bool) {
        const updatedValues = { [name]: { [error]: bool } };
        if (bool) errCount += 1;
        updateValues(updatedValues,setValues);
    }

    
    return (
    <>
    <Layout isLoading={isLoading} label="検索中..." headerLabel={displayName.C005}>
        <SectionWithText label={"再発行を希望する対象月を選択してください。\n最大で2か月分の選択が可能です。"}>
            <FormInputWrapper label="対象年月1" required={true} multi={true} calendar={true}>
                <p className="calendar">
                    <label className="calendar">
                        <input type="month" className={values.taisyouTuki1.requiredError ? "is-invalid" :""} onChange={handleCalendarChange} onClick={handleCalendarClick} onBlur={handleCalendarBlur} value={values.taisyouTuki1.value} name="taisyouTuki1" max={values.taisyouTuki1.max} min={values.taisyouTuki1.min} />
                    </label>
                </p>
                <FormValidationError formDataList={[values.taisyouTuki1]} type="calendar"></FormValidationError>
            </FormInputWrapper>
            <FormInputWrapper label="対象年月2" multi={true} calendar={true}>
                <p className="calendar">
                    <label className="calendar">
                        <input type="month" className={values.taisyouTuki2.duplicationError ? "is-invalid" :""} onChange={handleCalendarChange} onClick={handleCalendarClick} onBlur={handleCalendarBlur} value={values.taisyouTuki2.value} name="taisyouTuki2" max={values.taisyouTuki2.max} min={values.taisyouTuki2.min} />
                    </label>
                </p>
                <FormValidationError formDataList={[values.taisyouTuki2]} type="calendar"></FormValidationError>
            </FormInputWrapper>
        </SectionWithText>
        <SectionWithText label="ご登録の住所以外への送付を希望する場合のみ以下を入力ください。">
            <p className="annotation ml">※変更がない場合は入力不要です。</p>
            <FormInputWrapper label="宛名（漢字）">
                <p><InputText name="atena" formData={values.atena} handleValue={handleValue}></InputText></p>
                <FormValidationError formDataList={[values.atena]}></FormValidationError>
            </FormInputWrapper>
            <FormInputWrapper label="送付先住所">
                <TermInputPair label="郵便番号" annotation="※ハイフンなしで検索ください" className="zip">
                    <ZipCodeSearch name="yubinBangou" formData={values.yubinBangou} searchClick={searchClick} handleValue={handleValue}></ZipCodeSearch>
                </TermInputPair>
                <FormValidationError formDataList={[values.yubinBangou]} type={"yubinBangou"}></FormValidationError>
                <dl>
                    <div className="explanation-box"><p className="explanation-box_txt">検索できない場合は、以下を直接入力してください</p></div>
                </dl>
                <TermInputPair label="都道府県">
                    <CustomSelect name="toDouHuKen" options={allTodouHuKen} formData={values.toDouHuKen} handleValue={tdhChange} disabled={false}/>
                </TermInputPair>
                <FormValidationError formDataList={[values.toDouHuKen]} type={"addressSelect"}></FormValidationError>
                <TermInputPair label="市区町村">
                    <CustomSelect name="siKuTyouSon"options={values.siKuTyouSonOption.value} formData={values.siKuTyouSon} handleValue={sktsChange} disabled={values.toDouHuKen.value === ""} />
                </TermInputPair>
                <FormValidationError formDataList={[values.siKuTyouSon]} type={"addressSelect"}></FormValidationError>
                <TermInputPair label="字丁目">
                    <CustomSelect name="azaTyoume"options={values.azaTyoumeOption.value} formData={values.azaTyoume} handleValue={atmChange} disabled={values.siKuTyouSon.value === ""} />
                </TermInputPair>
                <FormValidationError formDataList={[values.azaTyoume]} type={"addressSelect"}></FormValidationError>
                <TermInputPair label="番地号">
                    <InputText name="bantiGou" formData={values.bantiGou} handleValue={handleValue}></InputText>
                </TermInputPair>
                <TermInputPair label="建物名">
                    <InputText name="tatemonoMei" formData={values.tatemonoMei} handleValue={handleValue}></InputText>
                </TermInputPair>
                <TermInputPair label="部屋番号">
                    <InputText name="heyaBangou" formData={values.heyaBangou} handleValue={handleValue}></InputText>
                </TermInputPair>
                <p className="annotation">マンション・アパートの場合は、建物名、部屋番号を必ず入力してください</p>
            </FormInputWrapper>
        </SectionWithText>
        <Submit label="送信" onClick={submit} error={values.systemError} disabled={isSubmitDisabled}></Submit>
    </Layout>
    </>
    )}